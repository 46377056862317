import React, { Component } from 'react';
import { Form, Button, Jumbotron, Row, Col } from 'react-bootstrap'
import { tab_list } from './optionList';
import sadcone from '../img/sadcone.png';
import happycone from '../img/happycone.png';

class UserForm extends Component {
      constructor(props) {
            super(props);
            this.state = {}
            // Binding getQuotes to our component since we'll be passing this
            // method to child components
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
      }

      componentDidMount() {
            // Restructure lines array
            const init_state = {}, 
            rows = tab_list;
            let row_len = rows.length;
            for( var i = row_len; i--; ){
                  const tab = rows[i];

                  if(this.props.perfil.tabs.indexOf(tab) > -1) {
                        init_state[tab] = true;
                  } else {
                        init_state[tab] = false;
                  }

            };

            // Initial state
            init_state['ciudad'] = this.props.perfil.ciudad;
            this.setState(init_state);
      }

      handleInputChange(event) {
            const target = event.target,
            name = tab_list.indexOf(target.name) > -1 ? target.type : target.name,
            og_name = name === 'checkbox' ? target.name : name;
            let value;

            // Check field name
            switch(name) {
                  case 'ciudad':
                        value = target.value
                        break;
                  case 'checkbox':
                        value = target.checked
                        break;
                  default:
                        value = ''
            }

            // Update state
            this.setState({
                  [og_name]: value
            });
      }

      handleSubmit(event) {
            event.preventDefault();
            const ciudad = this.state.ciudad,
            perfil = { ciudad }, 
            rows = tab_list;
            let row_len = rows.length,
            tabs = [];
            for( var i = row_len; i--; ){
                  const tab = rows[i];
                  if(this.state[tab]) {
                        tabs.push(tab);
                  }

            };

            // Close jumbotron and update profile
            perfil['tabs'] = tabs;
            this.props.toggleJumbo();
            this.props.updatePerfil(perfil);
            // console.log('FORM SUBMIT! ',perfil);

      }

      render() {
            const azulLen = this.props.azulPins.length,
            handleSubmit = this.handleSubmit,
            handleInputChange = this.handleInputChange,
            props = this.props,
            toggleJumbo = props.toggleJumbo,
            quitarMuchosFavs = props.quitarMuchosFavs,
            state = this.state,
            ciudad = state.ciudad,
            food = state.food,
            drink = state.drink,
            lodge = state.lodge,
            pharmacy = state.pharmacy,
            barber = state.barber,
            salon = state.salon,
            market = state.market,
            medical = state.medical,
            repair = state.repair,
            weed = state.weed,
            event = state.event,
            movie = state.movie;
            // console.log(this.props.azulPins);
            return (
                  <Jumbotron className="login formulario">
                        <Form onSubmit={handleSubmit} className="opaque-white">
                              <Form.Group controlId="ciudad">
                                    <Form.Label className='titulo'>City</Form.Label>
                                    <Form.Control as="select"
                                          className="disable_focus_outline"
                                          value={ciudad} 
                                          name="ciudad" 
                                          onChange={handleInputChange}
                                    >
                                          <option value={1}>Los Angeles</option>
                                          <option value={2}>San Francisco</option>
                                          <option value={3}>New York</option>
                                          <option value={4}>Chicago</option>
                                          <option value={5}>Washington D.C.</option>
                                          <option value={6}>Boston</option>
                                          <option value={7}>Fukuoka</option>
                                    </Form.Control>
                              </Form.Group>
                              <p className='titulo'>Tabs</p>
                              <Row>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="food">
                                                <Form.Check type="checkbox" 
                                                      label="Food"
                                                      name="food"
                                                      checked={food}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="drink">
                                                <Form.Check type="checkbox" 
                                                      label="Drink"
                                                      name="drink"
                                                      checked={drink}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="lodge">
                                                <Form.Check type="checkbox" 
                                                      label="Lodge"
                                                      name="lodge"
                                                      checked={lodge}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="pharmacy">
                                                <Form.Check type="checkbox" 
                                                      label="Pharmacy"
                                                      name="pharmacy"
                                                      checked={pharmacy}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="barber">
                                                <Form.Check type="checkbox" 
                                                      label="Barber"
                                                      name="barber"
                                                      checked={barber}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="salon">
                                                <Form.Check type="checkbox" 
                                                      label="Salon"
                                                      name="salon"
                                                      checked={salon}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="market">
                                                <Form.Check type="checkbox" 
                                                      label="Market"
                                                      name="market"
                                                      checked={market}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="medical">
                                                <Form.Check type="checkbox" 
                                                      label="Medical"
                                                      name="medical"
                                                      checked={medical}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="repair">
                                                <Form.Check type="checkbox" 
                                                      label="Repair"
                                                      name="repair"
                                                      checked={repair}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="weed">
                                                <Form.Check type="checkbox" 
                                                      label="Weed"
                                                      name="weed"
                                                      checked={weed}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="event">
                                                <Form.Check type="checkbox" 
                                                      label="Events"
                                                      name="event"
                                                      checked={event}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                                    <Col xs={6} sm={4} lg={3}>
                                          <Form.Group controlId="movie">
                                                <Form.Check type="checkbox" 
                                                      label="Movies"
                                                      name="movie"
                                                      checked={movie}
                                                      onChange={handleInputChange}
                                                />
                                          </Form.Group>
                                    </Col>
                              </Row>
                              <Button variant="primary" type="submit">
                                    Save
                              </Button>
                              <Button variant="secondary" onClick={toggleJumbo}>
                                    Close
                              </Button>
                        </Form>
                        <div className="boleto opaque-white">
                              { azulLen ? `Aw yeah! ${azulLen}x stations have favorites!!` : 'Womp! You have no favorites...' }
                              <hr/>
                              { azulLen ?
                                    <img
                                          className="d-block BizDiv"
                                          src={happycone}
                                          alt="Happy Cone"
                                          onClick={() => quitarMuchosFavs('')}
                                    />
                                    : <img
                                          className="d-block BizDiv"
                                          src={sadcone}
                                          alt="Sad Cone"
                                    />
                              }
                        </div>

                  </Jumbotron>
            );
      }
}

export default UserForm;