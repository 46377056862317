import React, { Component } from 'react';
// fas fa-dot-circle fa-2x
// fas fa-spinner fa-2x fa-pulse
class PinComponent extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  marker_class: 'fas fa-dot-circle fa-2x'
            }
      }

      render() {
            const marker_class = this.state.marker_class,
            handleShow = this.props.handleShow,
            station = this.props.station,
            id = this.props.id,
            className = this.props.className;
            return (
                  <div onClick={() => handleShow(station)} id={id} className={className}>
                        <i class={marker_class}></i>
                  </div>
            )
            
      }
}

// const PinComponent = ({ id, className, onClick, station }) => <div onClick={() => onClick(station)} id={id} className={className}>
//     <i class="fas fa-dot-circle fa-2x"></i>
//   </div>;
// fas fa-spinner fa-2x fa-pulse

export default PinComponent;