import React, { Component } from 'react';
import albacore from '../img/nekoloader_albacore.png';
import tuna from '../img/nekoloader_tuna.png';
import tamago from '../img/nekoloader_tamago.png';
import neko from '../img/nekoloader.png';
const losGatos = [tuna,albacore,tamago];
class NekoLoader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      counter:0,
      nekoloader:neko
    }
    this.nuevoGato = this.nuevoGato.bind(this);
  }

  componentDidMount() {
    const nuevoGato = this.nuevoGato;
    this.interval = setInterval(() => nuevoGato(), 650);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nuevoGato() {
    let counter = this.state.counter;
    // Reset counter
    if(counter >= 2){
      counter = -1
    }
    // Increment
    counter += 1
    // New cat
    const nekoloader = losGatos[counter]
    // Set state
    this.setState({counter,nekoloader});
  }

  render() {
    return (<img
      className="d-block mw-75 mh-75 centerImg"
      src={this.state.nekoloader}
      alt="Neko Loader"
    />);
  }
}

export default NekoLoader;