import React, { Component } from 'react';
import { 
	Carousel, 
	Badge, 
	Accordion, 
	Card,
	// Form, 
	// Button,
	// Jumbotron,
	OverlayTrigger, 
	Popover
} from 'react-bootstrap';
import happycone from '../img/happycone.png';
import sadcone from '../img/sadcone.png';
import medicalImg from '../img/medical.png';
import nekomarker from '../img/nekomarker.png';
import hammerWrench from '../img/hammerWrench.png';
import salonImg from '../img/salon.png';
import marketImg from '../img/market.png';
import drinkImg from '../img/drink.png';
// import neko from '../img/nekoloader.png';
import Ads from './Ads';
import SearchForm from './SearchForm';

const ShowMoreComponent = ({ objeto }) => (
	<div className="boleto">
		{"Would you like to show more?"}
		<hr/>
		<Badge pill variant="danger" data-subtype={'subtype' in objeto ? objeto.subtype : ''} onClick={objeto.addl_flag} className={'show_more'}>
	    	SHOW MORE
		</Badge>
	</div>
)
const BizDivComponent = ({ data, constructURL, favorites, handleFavorite, handleUnfavorite }) => ('addl_flag' in data ?
	<ShowMoreComponent objeto={data} />
	: 'ads_flag' in data
	? <Ads advertiser={data.ads_flag} />
	: <div className="boleto opaque-white">
		<a href={constructURL(data.dba_name,data.address)} target="_blank" rel="noopener noreferrer">
			<div className='bizURL'>
				{data.dba_name}
			</div>
		</a>
		<hr/>
		<h1>Address:</h1>
		<br/>
		{data.address.split(', ')[0]}
		<br/>
		{data.address.split(', ')[1]}
		<br/>
		{data.address.split(', ')[2]}
		{data.address.toLowerCase() !== data.mail_address.toLowerCase() &&
			[<hr/>,
			<h1>Mail Address:</h1>,
			<br/>,
			data.mail_address.split(', ')[0],
			<br/>,
			data.mail_address.split(', ')[1],
			<br/>,
			data.mail_address.split(', ')[2]]
		}
		<hr/>
		<h1>{ data.station_id in favorites && new Set(favorites[data.station_id]).has(data.id) ? 'Favorite!' : 'Favorite?' }</h1>
		{ data.station_id in favorites && new Set(favorites[data.station_id]).has(data.id) ?
			<img
			  className="d-block BizDiv"
			  src={happycone}
			  alt="Happy Cone"
			  onClick={(stationId,dataId) => handleUnfavorite(data.station_id,data.id)}
			/>
			: <img
			  className="d-block BizDiv"
			  src={sadcone}
			  alt="Sad Cone"
			  onClick={(stationId,dataId) => handleFavorite(data.station_id,data.id)}
			/>
		}
	</div>
)

class CarouselComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			directory_flag: false,
			showTickets: false,
			showInfo: false,
			modal_arr: [],
			addl_modal_arr: []
		}
		// Binding getQuotes to our component since we'll be passing this
		// method to child components
		this.slugify = this.slugify.bind(this);
		this.constructURL = this.constructURL.bind(this);
		this.sortTimeURLs = this.sortTimeURLs.bind(this);
		this.sortDates = this.sortDates.bind(this);
		this.showInfoDiv = this.showInfoDiv.bind(this);
		this.showTicketDiv = this.showTicketDiv.bind(this);
		this.renderMovies = this.renderMovies.bind(this);
		this.renderEvents = this.renderEvents.bind(this);
		this.renderLines = this.renderLines.bind(this);
		this.renderItems = this.renderItems.bind(this);
		this.renderBizDivs = this.renderBizDivs.bind(this);
		this.renderAccordion = this.renderAccordion.bind(this);
		this.renderMovieInfo = this.renderMovieInfo.bind(this);
		this.renderMovieTickets = this.renderMovieTickets.bind(this);
		this.renderEventTickets = this.renderEventTickets.bind(this);
		this.renderResultados = this.renderResultados.bind(this);
		this.actualizarArr = this.actualizarArr.bind(this);
		this.superChunk = this.superChunk.bind(this);
		this.limpiarTiempo = this.limpiarTiempo.bind(this);
	}

	componentDidMount() {
		if(this.props.modal_arr && this.props.modal_arr.length){
			// console.log('MOUNT (L108)',this.props);

			// Chunk array & split
			const chunkLen = 12, actualizarArr = this.actualizarArr, 
			tmpObj = {addl_flag:actualizarArr},
			tmp_arr = this.props.modal_arr,
			directory_flag = typeof tmp_arr[0] != 'string' && 'directory' in tmp_arr[0],
			chunkArr = this.superChunk(tmp_arr,chunkLen), 
			addl_modal_arr = directory_flag ? chunkArr.map((objeto) => ({
				type:objeto.type,
				directory:objeto.directory.length > 1 ? objeto.directory.slice(1) : []
			})) : chunkArr.length > 1 ? chunkArr.slice(1) : [],
			tmp_addl_len = addl_modal_arr.length;
			let modal_arr = directory_flag ? chunkArr.map((objeto) => ({
				type:objeto.type,
				directory:objeto.directory[0]
			})) : chunkArr[0], rows, row_len;
			// console.log('INITIALIZED',modal_arr,addl_modal_arr);

			// Check if directory exists
			if(directory_flag){

				// Loop through directories && compare to addl_arr
			    rows = modal_arr;
			    row_len = rows.length;
			    for( var i = row_len; i--; ){
			      const tmp_dir_arr = tmp_arr[i].directory, addl_check = tmp_dir_arr.length > chunkLen,
			      tmp_dir_type = tmp_arr[i].type;

			      // Check addl length
			      if(addl_check){
			      	const addl_flag = actualizarArr, subtype = tmp_dir_type,
			      	tmpDirObj = {addl_flag,subtype};

			      	// Populate localized show more button
			      	modal_arr[i].directory.push(tmpDirObj);
			      }

			    };

			} else if( tmp_addl_len ){
				modal_arr.push(tmpObj);
			}
			// Set state
			this.setState({modal_arr,addl_modal_arr,directory_flag});
		}

	}

	actualizarArr(e) {
		const addl_check = this.state.addl_modal_arr.length;
		if(addl_check){

			// Update arrays
			const tmp_arr = this.state.modal_arr, tmp_len = tmp_arr.length - 1,
			addl_tmp_arr = this.state.addl_modal_arr, addl_tmp_len = addl_tmp_arr.length,
			directory_flag = this.state.directory_flag, subtipo = e.target.dataset.subtype ? e.target.dataset.subtype : 'n/a';
			// console.log('L160',this.props.data_type,tmp_arr,addl_tmp_arr);
			// Check if SHOW MORE needed
			let addl_modal_arr = [], rows, row_len, 
			modal_arr = directory_flag ? tmp_arr.map((objeto,idx) => ({
				type:objeto.type,
				directory:objeto.type !== subtipo ? objeto.directory : addl_tmp_arr[idx].directory.length ? objeto.directory.slice(0,objeto.directory.length - 1)
				.concat(addl_tmp_arr[idx].directory[0])
				.concat(objeto.directory.slice(objeto.directory.length - 1)) : objeto.directory.slice(0,objeto.directory.length - 1)
			})) : addl_tmp_arr.length ? tmp_arr.slice(0,tmp_len)
			.concat(addl_tmp_arr[0])
			.concat(tmp_arr.slice(tmp_len)) : tmp_arr.slice(0,tmp_len);
			// console.log('L171',modal_arr,addl_modal_arr);

			// Update addl arrays
			if(directory_flag){
				// console.log('DIRECTORY!');
				// Loop through directories
			    rows = addl_tmp_arr;
			    row_len = rows.length;
			    for( var i = row_len; i--; ){
					const tmp_addl_dir_len = rows[i].directory.length, tmp_addl_dir_type = rows[i].type;

					// Skip if not clicked subtipo
					if ( tmp_addl_dir_type !== subtipo ) { continue; }

					// Check directory length
					if(tmp_addl_dir_len > 1){
						rows[i].directory = rows[i].directory.slice(1);

					} else if( tmp_addl_dir_len ){
						rows[i].directory = [];

					}

					// Remove SHOW MORE
					if(!rows[i].directory.length){

						const mLen = modal_arr[i].directory.length - 1;
						modal_arr[i].directory = modal_arr[i].directory.slice(0,mLen);

					}

			    };
			    addl_modal_arr = rows;

			} else if( addl_tmp_len > 1 ){
				addl_modal_arr = addl_tmp_arr.slice(1);

			}

			// Remove SHOW MORE
			if( !addl_modal_arr.length && !directory_flag ){
			    // console.log('CHECK!',modal_arr,modal_arr.slice(0,modal_arr.length - 1));
				const new_len = modal_arr.length - 1;
		      	modal_arr = modal_arr.slice(0,new_len);

			}
			// console.log('LENGTH',addl_tmp_len);
			// console.log('L216',modal_arr,addl_modal_arr);
			// Set state
			this.setState({modal_arr,addl_modal_arr});
		}
	}

	superChunk(arr, size){
		// Variables & Helper functions
		const chunkArr = function(cArr){
			return Array.from({ length: Math.ceil(cArr.length / size) }, (v, i) =>
				cArr.slice(i * size, i * size + size)
			);
		}, directory_flag = typeof arr[0] != 'string' && 'directory' in arr[0];
		let new_arr;

		// Verify structure
		if(directory_flag) {

			// Chunk directory
			new_arr = arr.map((objeto) => ({
					type:objeto.type,
					directory:chunkArr(objeto.directory)
				})
			);

		} else {

			// Chunk array
			new_arr = chunkArr(arr);

		}

		return new_arr;
	}

	slugify(string) {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;',
		b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------',
		p = new RegExp(a.split('').join('|'), 'g');

		return string.toString().toLowerCase()
	    .replace(/\s+/g, '-') // Replace spaces with -
	    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
	    .replace(/&/g, '-and-') // Replace & with 'and'
	    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
	    .replace(/\-\-+/g, '-') // Replace multiple - with single -
	    .replace(/^-+/, '') // Trim - from start of text
	    .replace(/-+$/, '') // Trim - from end of text
	}

	constructURL(dba_name,address) {
		const tmp_url = `${dba_name} ${address}`
		return `https://www.google.com/search?q=${this.slugify(tmp_url)}`
	}

	limpiarTiempo(tiempo) {
		const tiempoArr = tiempo.split(":"),
		hora = tiempoArr[0] % 12 ? tiempoArr[0] % 12 : 12,
		minutos = tiempoArr[1],
		cambioHora = tiempoArr[0] > hora,
		incluirMinutos = minutos !== "00",
		nuevoTiempo = `${hora}${incluirMinutos ? `:${minutos}` : ''}${cambioHora ? ' PM' : ' AM'}`;
		return nuevoTiempo;
	}

	sortTimeURLs(timeURLsArr,k) {
		timeURLsArr.sort(function (a, b) {
		    a = a[k].split(':');
		    b = b[k].split(':');
		    return a[0] - b[0] || a[1] - b[1] || a[2] - b[2];
		});
		// console.log(timeURLsArr);
		return timeURLsArr;
	}

	sortDates(datesArr) {
		datesArr.sort(function (a, b) {
		    a = a.split('-');
		    b = b.split('-');
		    return a[0] - b[0] || a[1] - b[1] || a[2] - b[2];
		});
		return datesArr;
	}

	showTicketDiv() {
		this.setState(state => ({
	      showTickets: !state.showTickets
	    }));
	}

	showInfoDiv() {
		this.setState(state => ({
	      showInfo: !state.showInfo
	    }));
	}

	renderMovieInfo(mov) {
		const directors = mov.directors,
		cast = mov.cast, run_time = mov.run_time;
		let info_arr = [];

	    // Populate info
	    if(directors !== ''){

	    	info_arr.push(
				<div className="informacion">
					<span>{'Director(s):'}</span>
					<hr/>
					{directors.replace(/,/g,", ")}
				</div>
			);

	    }

	    if(cast !== ''){

	    	info_arr.push(
				<div className="informacion">
					<span>{'Cast:'}</span>
					<hr/>
					{cast.replace(/,/g,", ")}
				</div>
			);

	    }

	    if(run_time !== ''){

	    	info_arr.push(
				<div className="informacion">
					<span>{'Duration:'}</span>
					<hr/>
					{run_time.replace("PT",'').replace("M",'').replace("H",':')}
				</div>
			);

	    }

		return info_arr;
	}

	renderMovieTickets(datesObj) {
		
		let datetimes_arr = [];
		const datesArr = Object.keys( datesObj ),
		rows = this.props.insertAds( this.sortDates( datesArr ) ),
	    row_len = rows.length,
	    limpiarTiempo = this.limpiarTiempo,
	    sortTimeURLs = this.sortTimeURLs;
	    // Loop through rows
	    for( var i = row_len; i--; ){
	    	// console.log(i,row_len - i - 1);
	    	const tmp_row = rows[row_len - i - 1],
			ads_flag = typeof tmp_row === 'string' ? false : true;
			let ele;
			if(ads_flag){
				ele = <Ads advertiser={tmp_row.ads_flag} />;
			} else {
				// console.log(datesObj[tmp_row],tmp_row);
				const dt = tmp_row,
				timeURLs = sortTimeURLs( datesObj[dt], 'movie_time' );

				// Populate element
				ele = <div className="boleto">
					{dt}
					<hr/>
					{				
						timeURLs.map(
							(mObj) => mObj['movie_uri'] !== '' 
							? <Badge pill variant={"primary"} className="ticket_badge">
								<a href={mObj['movie_uri']} target="_blank" rel="noopener noreferrer">{limpiarTiempo(mObj['movie_time'])}</a>
							</Badge>
							: <Badge pill variant={"secondary"} className="ticket_badge">
								{limpiarTiempo(mObj['movie_time'])}
							</Badge>
						)
					}
				</div>;
			}
			datetimes_arr.push(ele);

		}
		return datetimes_arr;
	}

	renderAccordion(movieObj) {
		const theatersObj = movieObj.theaters,
		total_count = movieObj.count,
		rows = Object.keys( theatersObj ),
	    row_len = rows.length,
	    renderMovieTickets = this.renderMovieTickets;
	    let links_arr = [];

		// Loop through rows
	    for( var i = row_len; i--; ){
			const theater_name = rows[i],
			datesObj = theatersObj[theater_name],
			dates_len = Object.keys( datesObj ).length,
			avg_count = total_count / dates_len;

			links_arr.push(<Card>
				<Card.Header>
					<Accordion.Toggle as={Card.Header} eventKey={i} className="accordion_link">
						{theater_name}
					</Accordion.Toggle>
				</Card.Header>
				<Accordion.Collapse eventKey={i}>
					<Card.Body className={ avg_count > 4 ? "scroll_card" : "" }>
						{renderMovieTickets(datesObj)}
					</Card.Body>
				</Accordion.Collapse>
			</Card>);

		}

		return links_arr;
	}

	renderMovies() {
		const state = this.state,
		modal_arr = state.modal_arr,
		showInfo = state.showInfo,
		renderMovieInfo = this.renderMovieInfo,
		showInfoDiv = this.showInfoDiv,
		renderAccordion = this.renderAccordion;
		return modal_arr && modal_arr.map((movieObj) => ('addl_flag' in movieObj ?
			<Carousel.Item style={{ textAlign:'center' }}>
					<img
						className="d-block carousel_img"
						src={nekomarker}
						alt={'Neko Marker'}
					/>
				<ShowMoreComponent objeto={movieObj} />
			</Carousel.Item>
			: <Carousel.Item>
					<img
						className="d-block mw-75 mh-75 peliculaImg"
						src={movieObj.movie.img_uri}
						alt="Movie Background"
					/>
					{ 
						showInfo ?
			        	<div className="info_div">
			        		{renderMovieInfo(movieObj.movie)}
						</div> :
						''
					}
					<Carousel.Caption className="carousel-caption-top movies_carousel">
				        {
				        	movieObj.movie.title.indexOf(':') !== -1 ? 
				        	<Badge pill variant="dark" className={movieObj.movie.title.length < 50 ? "title_badge peliculaTitulo" : "title_badge minify peliculaTitulo"}>
				        		{movieObj.movie.title.split(':')[0]}:<br/>{movieObj.movie.title.split(':')[1]} ({movieObj.movie.rating.length !== 0 ? movieObj.movie.rating : 'N/A'})
				        	</Badge>
				        	: movieObj.movie.title.indexOf(' - ') !== -1 ? 
				        	<Badge pill variant="dark" className={movieObj.movie.title.length < 50 ? "title_badge peliculaTitulo" : "title_badge minify peliculaTitulo"}>
				        		{movieObj.movie.title.split(' - ')[0]}:<br/>{movieObj.movie.title.split(' - ')[1]} ({movieObj.movie.rating.length !== 0 ? movieObj.movie.rating : 'N/A'})
				        	</Badge>
				        	: movieObj.movie.title.indexOf(' Presents ') !== -1 ? 
				        	<Badge pill variant="dark" className={movieObj.movie.title.length < 50 ? "title_badge peliculaTitulo" : "title_badge minify peliculaTitulo"}>
				        		{movieObj.movie.title.split(' Presents ')[0]} Presents:<br/>{movieObj.movie.title.split(' Presents ')[1]} ({movieObj.movie.rating.length !== 0 ? movieObj.movie.rating : 'N/A'})
				        	</Badge>
				        	: <Badge pill variant="dark" className={movieObj.movie.title.length < 50 ? "title_badge peliculaTitulo" : "title_badge minify peliculaTitulo"}>
					            {movieObj.movie.title} ({movieObj.movie.rating.length !== 0 ? movieObj.movie.rating : 'N/A'})
					        </Badge>
						}
				        <br/>
				        {
				        	<Badge pill variant="primary" onClick={showInfoDiv} className={ showInfo ? 'active_ticket_div' : '' }>
					        	more info...
				        	</Badge>
				        }
				        {
				        	movieObj.movie.genres.split(",").map(
				        		(g) => <Badge pill variant="info">{g}</Badge>
				        	)
				        }
				        <p className="reparto">
				        	{movieObj.movie.description}
				        </p>
				    </Carousel.Caption>
					<Carousel.Caption className="movies_carousel theater_accordion">
						<Accordion>
						{ renderAccordion(movieObj) }
						</Accordion>
					</Carousel.Caption>
				</Carousel.Item>
			)
		)
	}

	renderEventTickets(datesObj) {
		let datetimes_arr = [];
		const datesArr = Object.keys( datesObj ),
		rows = this.props.insertAds( this.sortDates( datesArr ) ),
	    row_len = rows.length,
	    limpiarTiempo = this.limpiarTiempo,
	    sortTimeURLs = this.sortTimeURLs;

	    // Loop through rows
	    for( var i = row_len; i--; ){
			const tmp_row = rows[row_len - i - 1],
			ads_flag = typeof tmp_row === 'string' ? false : true;
			let ele;
			if(ads_flag){
				ele = <Ads advertiser={tmp_row.ads_flag} />;
			} else {
				const dt = tmp_row,
				timeURLs = sortTimeURLs( datesObj[dt], 'event_time' );

				// Populate element
				ele = <div className="boleto">
					{dt}
					<hr/>
					{				
						timeURLs.map(
							(eObj) => eObj['event_url'] !== '' 
							? <Badge pill variant={"primary"} className="ticket_badge">
								<a href={eObj['event_url']} target="_blank" rel="noopener noreferrer">{limpiarTiempo( eObj['event_time'].split(":").slice(0,2).join(':') )}</a>
							</Badge>
							: <Badge pill variant={"secondary"} className="ticket_badge">
								{limpiarTiempo( eObj['event_time'].split(":").slice(0,2).join(':') )}
							</Badge>
						)
					}
				</div>;
			};

			datetimes_arr.push(ele);

		}

		return datetimes_arr;
	}

	renderEvents() {
		const state = this.state,
		modal_arr = state.modal_arr,
		showTicketDiv = this.showTicketDiv,
		showTickets = state.showTickets,
		renderEventTickets = this.renderEventTickets;
		return modal_arr && modal_arr.map((event) => ('addl_flag' in event ?
			<Carousel.Item style={{ textAlign:'center' }}>
					<img
						className="d-block carousel_img"
						src={nekomarker}
						alt={'Neko Marker'}
					/>
				<ShowMoreComponent objeto={event} />
			</Carousel.Item>
			: <Carousel.Item>
					<img
						className="d-block carousel_img"
						src={event.img_url}
						alt="Event Background"
					/>
					<Carousel.Caption className="carousel-caption-top">
			            {
			            	event.event_name.indexOf(':') !== -1 ? 
			            	<Badge pill variant="dark" className={event.event_name.length < 50 ? "title_badge" : "title_badge minify"}>
			            		{event.event_name.split(':')[0]}:<br/>{event.event_name.split(':')[1]}
			            	</Badge>
			            	: event.event_name.indexOf(' - ') !== -1 ? 
			            	<Badge pill variant="dark" className={event.event_name.length < 50 ? "title_badge" : "title_badge minify"}>
			            		{event.event_name.split(' - ')[0]}:<br/>{event.event_name.split(' - ')[1]}
			            	</Badge>
			            	: event.event_name.indexOf(' Presents ') !== -1 ? 
			            	<Badge pill variant="dark" className={event.event_name.length < 50 ? "title_badge" : "title_badge minify"}>
			            		{event.event_name.split(' Presents ')[0]} Presents:<br/>{event.event_name.split(' Presents ')[1]}
			            	</Badge>
			            	: <Badge pill variant="dark" className={event.event_name.length < 50 ? "title_badge" : "title_badge minify"}>
			            		{event.event_name}
			            	</Badge>
			            }
				        <Badge pill variant="dark" className='lugarMobile'>
			            	{event.venue} ({event.distance}{event.units.toLowerCase().replace("miles"," miles")})
			        	</Badge>
			        	<Badge pill variant="primary" onClick={showTicketDiv} className={ showTickets ? 'active_ticket_div boletoMobile' : 'boletoMobile' }>
				        	get tickets
			        	</Badge>
				    </Carousel.Caption>
					<Carousel.Caption>
						<Badge pill variant="dark" className='lugarDesk'>
			            	{event.venue} ({event.distance}{event.units})
			        	</Badge>

			        	{ 
			        		showTickets ?
				        	<div className="ticket_div">
								{renderEventTickets(event.dates)}
							</div> :
							''
						}

				        <Badge pill variant="primary" onClick={showTicketDiv} className={ showTickets ? 'active_ticket_div boletoDesk' : 'boletoDesk' }>
				        	get tickets
			        	</Badge>
				        <br/>
						{event.genre !== '' ? 
							<Badge pill variant="light" className="detalle">
				            	{event.genre} (genre)
				        	</Badge>
				        : ''}
				        {event.subgenre !== '' ? 
							<Badge pill variant="light" className="detalle">
								{event.subgenre} (subgenre)
							</Badge>
				        : ''}
				        {event.segment !== '' ? 
							<Badge pill variant="light" className="detalle">
				            	{event.segment} (segment)
				        	</Badge>
				        : ''}
					</Carousel.Caption>
				</Carousel.Item>
			)
		)
	}

	renderLocal(tipo) {
		let tmpSrc, tmpAlt;
		const modal_arr = this.state.modal_arr,
		constructURL = this.constructURL,
		props = this.props,
		favorites = props.favorites,
		handleFavorite = props.handleFavorite,
		handleUnfavorite = props.handleUnfavorite;
		switch(tipo) {
			case 'medical':
				tmpSrc = medicalImg
				tmpAlt = 'Medical Background'
				break;
			case 'repair':
				tmpSrc = hammerWrench
				tmpAlt = 'Repair Hammer & Wrench'
				break;
			case 'salon':
				tmpSrc = salonImg
				tmpAlt = 'Salon Background'
				break;
			case 'market':
				tmpSrc = marketImg
				tmpAlt = 'Market Background'
				break;
			case 'drink':
				tmpSrc = drinkImg
				tmpAlt = 'Drink Background'
				break;
			default:
				tmpSrc = nekomarker
				tmpAlt = 'Neko Marker'
		}
		// console.log('L608',this.state);
		return modal_arr && modal_arr.map((poi) => (<Carousel.Item>
					<img
						className="d-block carousel_img"
						src={tmpSrc}
						alt={tmpAlt}
					/>
					<Carousel.Caption className="carousel-caption-top">
						{
			            	poi.type.indexOf('(') !== -1 ? 
			            	<Badge pill variant="dark" className={poi.type.length < 50 ? "title_badge" : "title_badge minify"}>
			            		{poi.type.split('(')[0]}<br/>({poi.type.split('(')[1]}
			            	</Badge>
			            	: <Badge pill variant="dark" className={poi.type.length < 50 ? "title_badge" : "title_badge minify"}>
			            		{poi.type.replace(/ and /g, " & ")}
			            	</Badge>
			            }
				    </Carousel.Caption>
					<Carousel.Caption className="local-caption">
						{	
							poi.directory.map(
								(data) => ('addl_flag' in data
									? <ShowMoreComponent objeto={data} />
									: <BizDivComponent
									key={data.id}
									constructURL={constructURL}
									data={data}
									favorites={favorites}
									handleFavorite={(stationId,dataId) => handleFavorite(data.station_id,data.id)}
									handleUnfavorite={(stationId,dataId) => handleUnfavorite(data.station_id,data.id)}
								/>)
							)
						}
					</Carousel.Caption>
				</Carousel.Item>
			)
		)
	}

	renderBizDivs() {
		const constructURL = this.constructURL,
		props = this.props,
		favorites = props.favorites,
		handleFavorite = props.handleFavorite,
		handleUnfavorite = props.handleUnfavorite,
	    modal_arr = this.state.modal_arr;
		return modal_arr && modal_arr.map((biz) => (
				<BizDivComponent
					key={biz.id}
					constructURL={constructURL}
					data={biz}
					favorites={favorites}
					handleFavorite={handleFavorite}
					handleUnfavorite={handleUnfavorite}
				/>
			)
		)
	}

	renderLines() {
		const tiemposAPI = this.props.tiemposAPI,
	    showTiempo = this.props.showTiempo,
	    etaTXT = this.props.etaTXT,
	    otroTXT = this.props.otroTXT,
	    modal_arr = this.state.modal_arr;
		return modal_arr && modal_arr.map((line) => ( !Array.isArray(line) && 'addl_flag' in line
			? <ShowMoreComponent objeto={line} />
			: <OverlayTrigger
				trigger="click"
				key="top"
				placement="top"
				overlay={
					<Popover id={`${line[0]}`} show={showTiempo}>
						<Popover.Title as="h3">{'Next trip...'}</Popover.Title>
						<Popover.Content>
							{etaTXT.indexOf('ALTABERIA') !== -1 ? 
								<a href={`${etaTXT.replace('ALTABERIA','')}`} target="_blank" rel="noopener noreferrer">
									<h2>{`click here (${line[1]})`}</h2>
								</a> 
								: etaTXT
							}
							{otroTXT && [<hr/>,
								<h2>Other direction:</h2>,
								otroTXT
							]}
						</Popover.Content>
					</Popover>
				}
				rootClose
				>
					<Badge pill variant="dark" className="lines_badge" onClick={() => tiemposAPI(line)}>
						{`${line[1]} `}<i class="far fa-hand-pointer"></i>
					</Badge>
				</OverlayTrigger>
			)
		)
	}

	renderResultados(resultados) {
		// console.log("RESULTADOS: ",resultados);
		const constructURL = this.constructURL,
		props = this.props,
		favorites = props.favorites,
		handleFavorite = props.handleFavorite,
		handleUnfavorite = props.handleUnfavorite;
		return resultados.map((biz) => (
				<BizDivComponent
					key={biz.id}
					constructURL={constructURL}
					data={biz}
					favorites={favorites}
					handleFavorite={handleFavorite}
					handleUnfavorite={handleUnfavorite}
				/>
			)
		)
	}

	renderItems() {
		// console.log('CAROUSEL (L737): ',this.props.data_type,this.state);
		switch(this.props.data_type){

			case 'events':
				return this.renderEvents();

			case 'movies':
				return this.renderMovies();

			case 'medical':
				return this.renderLocal('medical');

			case 'drink':
				return this.renderLocal('drink');

			case 'salon':
				return this.renderLocal('salon');

			case 'market':
				return this.renderLocal('market');

			case 'repair':
				return this.renderLocal('repair');

			case 'local':
				return this.renderLocal('local');

			case 'lines':
				return this.renderLines();

			default:
				return this.renderBizDivs();

		};
	}

	render() {
		const props = this.props,
		data_type = props.data_type,
		busqueda = props.busqueda,
		nullifyBusqueda = props.nullifyBusqueda,
		actualizarBusqueda = props.actualizarBusqueda,
		nuevosResultados = props.nuevosResultados,
		renderResultados = this.renderResultados,
		renderItems = this.renderItems,
		modal_arr = this.state.modal_arr;
		if (['events','movies','medical','local','market','salon','repair','drink'].indexOf(data_type) > -1){
			return (
				<Carousel interval={null} touch={false} className={modal_arr.length > 1 ? '' : 'noIndicators'}>
					{renderItems()}
				</Carousel>
			);

		} else {
			return (
				<div className="noCarousel">
					{data_type === "resultados" 
						? <SearchForm 
							busqueda={busqueda}
							nullifyBusqueda={nullifyBusqueda}
							actualizarBusqueda={actualizarBusqueda}
							nuevosResultados={nuevosResultados}
							renderResultados={renderResultados}
						/> 
						: renderItems()
					}
				</div>
			);

		}

	}
}

export default CarouselComponent;