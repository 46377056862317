const tab_list = [
  'food',
  'drink',
  'lodge',
  'weed',
  'medical',
  'market',
  'pharmacy',
  'barber',
  'salon',
  'repair',
  'movie',
  'event'
]

export { tab_list };