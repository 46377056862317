import React, { Component } from 'react';
import { Form, Button, Jumbotron} from 'react-bootstrap'

class SearchForm extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  resultados: []
            }
            // Binding getQuotes to our component since we'll be passing this
            // method to child components
            this.actualizarResultados = this.actualizarResultados.bind(this);
            this.resetBusqueda = this.resetBusqueda.bind(this);
      }

      // Using state function format to allow state to finish updating before rendering (setState is async)
      actualizarResultados(event) {
            event.preventDefault();
            function setStateFunction(state, props) {
                  const newState = {...state, resultados: props.nuevosResultados()};
                  return newState;
            }
            this.setState(setStateFunction);

      }

      resetBusqueda(event) {
            event.preventDefault();
            function setStateFunction(state, props) {
                  const newState = {...state, resultados: []};
                  return newState;
            }
            this.setState(setStateFunction);
            this.props.nullifyBusqueda();
      }

      render() {
            return (
                  <Jumbotron className="login formulario busqueda">
                        <Form onSubmit={this.actualizarResultados} className="opaque-white">
                              <Form.Group controlId="busqueda">
                                    <Form.Label className='titulo'>Keywords</Form.Label>
                                    <Form.Control
                                          className="disable_focus_outline"
                                          value={this.props.busqueda} 
                                          name="busqueda" 
                                          placeholder="[ Korean Tacos ][ Korean && Tacos ][ Korean || Tacos ]"
                                          onChange={this.props.actualizarBusqueda}
                                    />
                              </Form.Group>
                              <Button variant="primary" type="submit" className="disable_focus_outline busqueda">
                              Submit
                              </Button>
                              <Button variant="secondary" type="reset" onClick={this.resetBusqueda} className="disable_focus_outline busqueda">
                              Reset
                              </Button>
                        </Form>
                        <hr/>
                        {this.state.resultados.length 
                              ? this.props.renderResultados(this.state.resultados)
                              : <div className="boleto">
                                    {"Example Search (case-insensitive)!"}
                                    <hr/>
                                    <p>Korean Tacos => results 'Korean Tacos'</p>
                                    <p>Korean && Tacos => results 'Korean' and 'Tacos'</p>
                                    <p>Korean || Tacos => results 'Korean' or 'Tacos'</p>
                              </div>
                        }
                        {this.state.resultados.length === 1 && <div className="boleto">
                              {"No results! :("}
                              <hr/>
                              Try using different keywords!
                        </div>}
                  </Jumbotron>
            );
      }
}

export default SearchForm;