import axios from "axios";
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import TabsComponent from './TabsComponent';
import UserForm from './UserForm';
import PinComponent from './PinComponent';
import { Modal, Button, Badge, OverlayTrigger, Popover } from 'react-bootstrap'
import '../css/all.css';
import '../js/all.js';
import { styles_list } from './mapStyles';
import sadcone from '../img/sadcone.png';
import happycone from '../img/happycone.png';
// import neko from '../img/neko.png';
import { stringify, parse } from 'zipson';

// const PinComponent = ({ id, className, onClick, station }) => <div onClick={() => onClick(station)} id={id} className={className}>
//     <i class="fas fa-dot-circle fa-2x"></i>
//   </div>;
// fas fa-spinner fa-pulse
class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: props.agency_map[props.usuario.perfil.ciudad]['lat'],
        lng: props.agency_map[props.usuario.perfil.ciudad]['long']
      },
      zoom: props.agency_map[props.usuario.perfil.ciudad]['zoom'],
      show: false,
      favorite: false,
      jumbo: false,
      showTiempo: false,
      ogTXT: 'Loading...',
      etaTXT: 'Loading...',
      otroTXT: '',
      styles: styles_list
    }

    // Binding getQuotes to our component since we'll be passing this
    // method to child components
    this.renderTransitLines = this.renderTransitLines.bind(this);
    this.renderMarkers = this.renderMarkers.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggleJumbo = this.toggleJumbo.bind(this);
    this.tiemposAPI = this.tiemposAPI.bind(this);
    this.limpiarTiempo = this.limpiarTiempo.bind(this);
    this.superReverse = this.superReverse.bind(this);
  }

  handleShow(station) {
    // Variables
    const sid = station.station_id,
    movie_map = this.props.movie_map,
    theater_map = this.props.theater_map,
    theater_showtime_map = this.props.theater_showtime_map,
    station_venue_map = this.props.station_venue_map,
    station_theater_map = this.props.station_theater_map,
    station_business_map = this.props.station_business_map,
    venue_event_map = this.props.venue_event_map,
    venue_map = this.props.venue_map,
    genre_map = this.props.genre_map,
    subgenre_map = this.props.subgenre_map,
    segment_map = this.props.segment_map,
    line_map = this.props.line_map,
    business_map = this.props.business_map,
    naics_map = this.props.naics_map,
    station_businesses = station_business_map[sid],
    station_theaters = station_theater_map[sid],
    station_venues = station_venue_map[sid],
    show_pos_map = {},
    event_pos_map = {},
    drink_pos_map = {},
    med_pos_map = {},
    biz_pos_map = {},
    salon_pos_map = {},
    market_pos_map = {},
    repair_pos_map = {},
    naics_food_set = new Set(['Udon restaurant','The filtrate store (stationary stalls)','Sushi bar','Soba restaurant','Restaurant','Side dish','Movement A','Moving B','Moving business','Mainly cafe','Lunch vendor','722110','722211','7220-7229','Sidewalk Cafe','comida','735','775','781','782','9313','9305','Non Alcohol','FOOD','General cafeteria']),
    naics_drink_set = new Set(['722410','COFFEE','638','829','784','785','Alcohol','bar','ALCOHOL']),
    naics_weed_set = new Set(['888888']),
    naics_lodge_set = new Set(['721100','5107','5101','5110','676','LODGE','676 | 670','676 | 745','Ryokan','Hotel','Inn']),
    naics_med_set = new Set(['hospital','622000','621111','621210','621310','621320','621330','621340','621391','621399','621400','541940','857','dentista']),
    naics_market_set = new Set(['vending machine','445100','445310','447100','445210','445220','445230','MARKET','GROCERY','DELI','LIQOUR','BAKERY','MINI MART','TOBACCO','774','775','767','776','932','937','9301','9308','Bakery','Package']),
    naics_pharmacy_set = new Set(['446110','PHARMACY']),
    naics_barber_set = new Set(['812111','BARBER','9201','Barber shop']),
    naics_salon_set = new Set(['812113','812112','HAIR_SALON','NAIL_SALON','Beauty salon']),
    naics_repair_set = new Set(['811110','811120','811190','811210','811310','811410','811420','811430','811490','712','673','923','713','4201','8004','711']),
    // Add below only if adding above is too broad (i.e. includes too much w/out filtering)
    food_set = new Set(['','None','Common','Retail','Chinese shop']),
    alcohol_set = new Set(['','Mainly cafe','Lunch vendor','Retail','Chinese shop']),
    coffee_set = new Set(['','None','Moving business','Mainly cafe','Lunch vendor','Frozen seafood','Common','Bread','7210-7219','311400','311900','424400','4400-4599','445290','452000','453990','454390','722300','Electronic Cigarette Dealer','Stoop Line Stand','Cabaret','comida','638','735','781','774','775','782','776','932 | 774','9305','9306','Retail','Non Alcohol']),
    market_set = new Set(['','Side dish','Packaging seafood','Packaging meat','None','Moving business','Frozen seafood','Common','Bread','4400-4599','7220-7229','311400','311800','311900','722300','445290','452000','Electronic Cigarette Dealer','Stoop Line Stand','Tobacco Retail Dealer','comida','638','735','763','767 | 775','932 | 774','775 | 767','9305','9306','Retail']),
    pharmacy_set = new Set(['','Common','Tobacco Retail Dealer','Dealer In Products','774','775','767','767 | 775','932 | 774','775 | 767','9306','Retail']),
    hair_set = new Set(['894','144','904 | 894','9202','Beauty salon']),
    nail_set = new Set(['895','144','9202','Beauty salon']),
    lodge_set = new Set(['','Moving business','General cafeteria','Common','Tobacco Retail Dealer','Sidewalk Cafe','comida','Catering Establishment','Cabaret','9313','9307','9306','8100-8139','809','784','775','744','743','735','5107','5300-5399','531100','7210-7219','Retail','Alcohol','Non Alcohol','Simple lodging','Inns and hotels']),
    medical_set = new Set(['775','735','726','Garage','Parking Lot','General cafeteria','Health center']),
    repair_set = new Set(['','4400-4599','708','8100-8139','4400-4599','5400-5499','Electronic & Appliance Service',
      'Electronics Store','Home Improvement Contractor','Secondhand Dealer - Auto',
      'Secondhand Dealer - General','Tobacco Retail Dealer','REPAIR_AUTO','REPAIR_RELOJ',
      'REPAIR_SHOES','REPAIR_JEWEL','REPAIR_ELECTRONIC','REPAIR_HOME','REPAIR_WINDOW',
      'REPAIR_APPLIANCE']);
    let favs_arr = [], 
    events_arr = [],
    lines_arr = [],
    movies_arr = [],
    businesses_arr = [],
    food_arr = [], drink_arr = [], med_arr = [], lodge_arr = [],
    weed_arr = [], market_arr = [], pharmacy_arr = [], barber_arr = [],
    salon_arr = [], repair_arr = [],
    rows, rows2, row_len, row_len2,
    /* RegEx */
    isFood = new RegExp(/(?=\b(dimsum|dim sum|chicken|fried|boiled|grill|grilled|cafeteria|burger|burgers|tacos|taco|tacos|burrito|burritos|kitchen|bagel|bagels|takoyaki|sushi|ramen|bistro|waffle|waffles|wafflehouse|diner|dining|breakfast|lunch|dinner|pancakes|restaurant|bbq|sandwich|sandwiches|noodle|noodles|pasta|tofu|pizza|dumpling|dumplings|cuisine|ice cream|gelato|gelateria|paleteria|neveria|spaghetti)\b)\b\w+/g),
    isAlcohol = new RegExp(/(?=\b(tavern|pub|bar|cantina|cocktails)\b)\b\w+/g),
    isMini = new RegExp(/(?=\b(daily yamazaki|mini|mart|ministop|7 eleven|7-eleven|seven-eleven|convenience|newstand|newstands|milk|foodmart|express|family|familymart)\b)\b\w+/g),
    isBakery = new RegExp(/(?=\b(petit|bakery|baker|bakers|pan|panaderia|pasteleria|pastry|pastries|baguette|cookies|cupcakes|bread)\b)\b\w+/g), 
    isCoffee = new RegExp(/(?=\b(coffee|cafe|cafes|cafecito|coffeehouse|lebresso|espresso)\b)\b\w+/g),
    isLiqour = new RegExp(/(?=\b(liquor|liquors|wine|wines|spirits)\b)\b\w+/g),
    isTobacco = new RegExp(/(?=\b(tobacco|cigarettes|cigars|cigar|smoke)\b)\b\w+/g),
    isMarket = new RegExp(/(?=\b(meat|butcher|seafood|fish|daily yamazaki|marukyou|marushoku imajuku|supermarket|market|liquor|liquors|wine|wines|spirits|grocery|groceries|bakery|bakers|panaderia|pasteleria|pastry|pastries|baguette|cookies|cupcakes|bread|costco|max|maxvalu|deli|delicatessen|mini|mart|ministop|7 eleven|7-eleven|seven-eleven|express|family|familymart|tobacco|cigarettes|cigar|cigars|smoke|food|foods|produce|whole foods|trader joe|convenience|newstand|newstands|milk|foodmart)\b)\b\w+/g),
    isPharm = new RegExp(/(?=\b(pharmacy|duane reade|rite aid|walgreens|drug|drugstore|drugs|rx|farmacia)\b)\b\w+/g),
    isGrocery = new RegExp(/(?=\b(marukyou|marushoku imajuku|grocery|groceries|produce|costco|max)\b)\b\w+/g),
    isNailSalon = new RegExp(/(?=\b(nail|nails)\b)\b\w+/g),
    isLodge = new RegExp(/(?=\b(hotel|motel|inn|guest|house|ryokan|grand|villa|house)\b)\b\w+/g),
    isMedical = new RegExp(/(?=\b(hospital|hospitals)\b)\b\w+/g),
    isRepair = new RegExp(/(?=\b(repair|repairs)\b)\b\w+/g),
    isAuto = new RegExp(/(?=\b(auto|automotive|porsche|bmw|saab|jaguar|volvo|mercedes benz|toyota|honda|ford|gm|truck|motorcycle|tire|tires|volkswagen|land rover)\b)\b\w+/g),
    isReloj = new RegExp(/(?=\b(watch|watches)\b)\b\w+/g),
    isShoes = new RegExp(/(?=\b(shoe|shoes)\b)\b\w+/g),
    isJewel = new RegExp(/(?=\b(jewelry|jeweler)\b)\b\w+/g),
    isElectronic = new RegExp(/(?=\b(computer|phone|phones|cellphone|cellphones|cellular|wireless|iphone|android|pc|mac|macbook)\b)\b\w+/g),
    isHome = new RegExp(/(?=\b(house|home)\b)\b\w+/g),
    isWindow = new RegExp(/(?=\b(window|windows)\b)\b\w+/g);
    // isAppliance = new RegExp(/(?=\b(appliance|boiler|washer|dryer|appliances)\b)\b\w+/g);

    // Restructure lines array
    rows = station.line_ids;
    row_len = rows.length;
    for( var i = row_len; i--; ){
      const lid = rows[i],
      line_name = line_map[lid];

      // Push to line array
      lines_arr.push([lid,line_name,sid]);

    };

    // Restructure theaters into showtimes
    if( station_theaters ){

      rows = station_theaters;
      row_len = rows.length;
      for( var i = row_len; i--; ){
        const theater_id = rows[i],
        theater_name = theater_map[theater_id],
        tmp_map = theater_showtime_map[theater_id];

        // Populate theater keys into position dictionary
        if( !(theater_id in show_pos_map) ){
          show_pos_map[theater_id] = {};

        }

        // CONFIRM SHOWTIMES EXIST
        // if ( !(tmp_map) ) { continue; }

        // Populate show_dict
        rows2 = parse(stringify(tmp_map));
        row_len2 = rows2.length;
        for( var j = row_len2; j--; ){
          const showtime = rows2[j];

          // Skip invalid values resulting from grouping showtimes (i.e. null)
          if ( !(showtime) ) { continue; }

          const movie_id = showtime.movie_id,
          movie_uri = showtime.ticket_uri,
          datetimeArr = showtime.datetime.split('T'),
          movie_date = datetimeArr[0], movie_time = datetimeArr[1];

          // Populate position dictionary
          if ( !(movie_id in show_pos_map[theater_id]) ){

            // Populate movie details
            showtime['id'] = movie_id;
            showtime['station_id'] = sid;
            showtime['movie'] = movie_map[ movie_id ];

            // Add position to map
            show_pos_map[theater_id][movie_id] = movies_arr.length;

            // Restructure theater key-value into nested plural-list and track count of times for conditional className
            showtime['theaters'] = {};
            showtime['count'] = 0;

            // Delete old keys
            delete showtime['datetime'];
            delete showtime['ticket_uri'];

            // Push to showtimes array
            movies_arr.push(showtime);

          }

          // Use position from map
          const tmp_pos = show_pos_map[theater_id][movie_id];

          // Confirm theater exists in showtime
          if( !(theater_name in movies_arr[tmp_pos]['theaters']) ){
            movies_arr[tmp_pos]['theaters'][theater_name] = {};

          };

          // Populate theater dates
          if( !(movie_date in movies_arr[tmp_pos]['theaters'][theater_name]) ){
            movies_arr[tmp_pos]['theaters'][theater_name][movie_date] = [];

          };

          // Populate theaters
          movies_arr[tmp_pos]['theaters'][theater_name][movie_date].push( {movie_time, movie_uri} );
          movies_arr[tmp_pos]['count']++;

        };  

      }

    };

    // Construct events array from venues
    if( station_venues ){

      rows = station_venues;
      row_len = rows.length;
      for( var i = rows.length; i--; ){
        const venue = rows[i],
        venue_id = venue['venue_id'],
        venue_distance = venue['distance'],
        venue_units = venue['units'],
        venue_name = venue_map[venue_id],
        tmp_map = venue_event_map[venue_id];

        // Skip invalid values resulting from no events (i.e. undefined)
        if ( !(tmp_map) ) { continue; }

        // Populate venue keys into position dictionary
        if( !(venue_id in event_pos_map) ){
          event_pos_map[venue_id] = {};

        }

        // Loop through events
        rows2 = parse(stringify(tmp_map));
        row_len2 = rows2.length;
        for( var j = row_len2; j--; ){
          let event = rows2[j];

          // Skip invalid values resulting from grouping dates (i.e. null)
          if ( !(event) ) { continue; }

          const smid = event['segment_id'],
          gid = event['genre_id'],
          sgid = event['subgenre_id'],
          event_name = event['event_name'],
          event_date = event['date'],
          event_time = event['time'],
          event_url = event['event_url'];

          // Aggregate Dates & Times
          if( !(event_name in event_pos_map[venue_id]) ){
            event['dates'] = {};

            // Delete old keys
            delete event['genre_id'];
            delete event['subgenre_id'];
            delete event['segment_id'];
            delete event['venue_id'];
            delete event['date'];
            delete event['time'];
            delete event['event_url'];

            // Populate key-ids with corresponding names
            event['segment'] = segment_map[smid];
            event['genre'] = genre_map[gid];
            event['subgenre'] = subgenre_map[sgid];

            // Populate additional keys
            event['station_id'] = sid;
            event['venue'] = venue_name;
            event['distance'] = venue_distance;
            event['units'] = venue_units;

            // Populate position dict
            event_pos_map[venue_id][event_name] = events_arr.length;
            events_arr.push(event);

          }

          // Use position from map
          const tmp_pos = event_pos_map[venue_id][event_name];

          // Confirm date key exists in event
          if( !(event_date in events_arr[ tmp_pos ]['dates']) ){
            events_arr[ tmp_pos ]['dates'][event_date] = [];

          }

          // Push events to array
          events_arr[ tmp_pos ]['dates'][event_date].push( {event_time, event_url} );

        }

      }

    };

    // Construct business array
    if( station_businesses ){

      rows = station_businesses;
      row_len = rows.length;
      for( var i = rows.length; i--; ){
        const bid = rows[i],
        business = parse(stringify(business_map[bid]));
        let naics_code = business['naics_id'];

        // Add business_id & station_id
        business['id'] = bid;
        business['station_id'] = sid;

        // Delete old keys & Populate key-ids with corresponding names
        delete business['naics_id'];
        business['naics'] = naics_map[naics_code];

        // Titlecase & Format w/ RegEx
        business['naics'] = business['naics'].replace(/(?!\b(for|and|of)\b)\b\w+/g,function(s){return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();})
        .replace(/  /g,' ')
        .replace(/ and /g,' & ')
        .replace(/Including/g,'Incl.')
        .replace(/Except /g,'Exc. ')
        .replace(/Miscellaneous/g,'Misc.')
        .replace(/Automotive/g,'Auto')
        .replace(/With /g,'w/ ')
        .replace(/Without/g,'w/out')
        .replace(/( & |, & |, )+/g,'/');

        // 
        // Rename indirect grouping NAICs (to internals)
        // 
        const dba_name = business.dba_name.toLowerCase().replace('7- eleven','7 - eleven').replace('7 -eleven','7 - eleven').replace('7 - eleven','7-eleven');
        // COFFEE (Drink)
        if( coffee_set.has(naics_code) && dba_name.match(isCoffee) ){
          business['naics'] = 'Coffee';
          naics_code = 'COFFEE';

        }
        // ALCOHOL (Drink)
        else if( alcohol_set.has(naics_code) && dba_name.match(isAlcohol) ){
          business['naics'] = 'Alcohol';
          naics_code = 'ALCOHOL';

        }
        // LODGE
        else if( lodge_set.has(naics_code) && dba_name.match(isLodge) ){
          business['naics'] = 'Lodge';
          naics_code = 'LODGE';

        }
        // MEDICAL
        else if( medical_set.has(naics_code) && dba_name.match(isMedical) ){
          business['naics'] = 'Hospital';
          naics_code = 'HOSPITAL';

        }
        // PHARMACY
        else if( pharmacy_set.has(naics_code) && dba_name.match(isPharm) ){
          business['naics'] = 'Pharmacy';
          naics_code = 'PHARMACY';

        }
        // NAIL SALON
        else if( nail_set.has(naics_code) ){

          // Categorize
          if( dba_name.match(isNailSalon) ){
            business['naics'] = 'Nail Salon';
            naics_code = 'NAIL_SALON';
          }

        }
        // Hair (several types)
        else if( hair_set.has(naics_code) ){

          // Categorize
          if( dba_name.indexOf('barber') > -1 ){
            business['naics'] = 'Barber';
            naics_code = 'BARBER';
          } else {
            business['naics'] = 'Hair Salon';
            naics_code = 'HAIR_SALON';
          }

        }
        // Market (several types)
        else if( market_set.has(naics_code) && dba_name.match(isMarket) ){

          // Rename NAICS (if applicable)
          if(dba_name.match(isGrocery)){
            business['naics'] = 'Grocery';
            naics_code = 'GROCERY';

          } else if (dba_name.match(isBakery)){
            business['naics'] = 'Bakery';
            naics_code = 'BAKERY';

          } else if (dba_name.indexOf('deli') > -1){
            business['naics'] = 'Deli';
            naics_code = 'DELI';

          } else if (dba_name.match(isLiqour)){
            business['naics'] = 'Liquor';
            naics_code = 'LIQUOR';

          } else if (dba_name.match(isTobacco)){
            business['naics'] = 'Tobacco';
            naics_code = 'TOBACCO';

          } else if (dba_name.match(isMini)){
            business['naics'] = 'Mini Mart';
            naics_code = 'MINI MART';

          } else {
            business['naics'] = 'Market';
            naics_code = 'MARKET';

          }
        // Repair (several types)
        } else if( repair_set.has(naics_code) && dba_name.match(isRepair) ){

          // Rename NAICS (if applicable)
          if(dba_name.match(isAuto)){
            business['naics'] = 'Auto';
            naics_code = 'REPAIR_AUTO';

          } else if (dba_name.match(isReloj)){
            business['naics'] = 'Watches';
            naics_code = 'REPAIR_RELOJ';

          } else if (dba_name.match(isShoes)){
            business['naics'] = 'Shoes';
            naics_code = 'REPAIR_SHOES';

          } else if (dba_name.match(isJewel)){
            business['naics'] = 'Jewelry';
            naics_code = 'REPAIR_JEWEL';

          } else if (dba_name.match(isElectronic)){
            business['naics'] = 'Electronic';
            naics_code = 'REPAIR_ELECTRONIC';

          } else if (dba_name.match(isHome)){
            business['naics'] = 'Home';
            naics_code = 'REPAIR_HOME';

          } else if (dba_name.match(isWindow)){
            business['naics'] = 'Windows';
            naics_code = 'REPAIR_WINDOW';

          } else if (dba_name.match(isReloj)){
            business['naics'] = 'Appliances';
            naics_code = 'REPAIR_APPLIANCE';

          }

        }
        // FOOD
        else if( food_set.has(naics_code) ){

          // Categorize
          if( dba_name.match(isFood) ){
            business['naics'] = 'Food';
            naics_code = 'FOOD';
          }

        }

        // Check if favorite
        const fav_set = new Set(this.props.favorites[sid]);
        if( fav_set.has(bid) ){

          // Push to favorites array
          favs_arr.push(business)

        }

        // Filter tabbed businesses from misc.
        if( naics_food_set.has(naics_code) ){

          // Push food to array
          food_arr.push( business );

        } else if( naics_drink_set.has(naics_code) ){

          // Populate position dict & push drink to array
          if( !(naics_code in drink_pos_map) ){
            drink_pos_map[naics_code] = drink_arr.length;
            drink_arr.push( 
              {
                'type':business['naics'],
                'directory':[]
              } 
            );
          }
          drink_arr[ drink_pos_map[naics_code] ]['directory'].push( business );

        } else if( naics_weed_set.has(naics_code) ){

          // Push weed to array
          weed_arr.push( business );

        } else if( naics_med_set.has(naics_code) ){

          // Populate position dict & push med to array
          if( !(naics_code in med_pos_map) ){
            med_pos_map[naics_code] = med_arr.length;
            med_arr.push( 
              {
                'type':business['naics'],
                'directory':[]
              } 
            );
          }
          med_arr[ med_pos_map[naics_code] ]['directory'].push( business );

        } else if( naics_market_set.has(naics_code) ){

          // Populate position dict & push market to array
          if( !(naics_code in market_pos_map) ){
            market_pos_map[naics_code] = market_arr.length;
            market_arr.push( 
              {
                'type':business['naics'],
                'directory':[]
              } 
            );
          }
          market_arr[ market_pos_map[naics_code] ]['directory'].push( business );

        } else if( naics_salon_set.has(naics_code) ){

          // Populate position dict & push salon to array
          if( !(naics_code in salon_pos_map) ){
            salon_pos_map[naics_code] = salon_arr.length;
            salon_arr.push( 
              {
                'type':business['naics'],
                'directory':[]
              } 
            );
          }
          salon_arr[ salon_pos_map[naics_code] ]['directory'].push( business );

        } else if( naics_repair_set.has(naics_code) ){

          // Populate position dict & push repair to array
          if( !(naics_code in repair_pos_map) ){
            repair_pos_map[naics_code] = repair_arr.length;
            repair_arr.push( 
              {
                'type':business['naics'],
                'directory':[]
              } 
            );
          }
          repair_arr[ repair_pos_map[naics_code] ]['directory'].push( business );

        } else if( naics_lodge_set.has(naics_code) ){

          // Push lodge to array
          lodge_arr.push( business );

        } else if( naics_pharmacy_set.has(naics_code) ){

          // Push pharmacy to array
          pharmacy_arr.push( business );

        } else if( naics_barber_set.has(naics_code) ){

          // Push barber to array
          barber_arr.push( business );

        } else {

          // Populate position dict & push lodge to array
          if( !(naics_code in biz_pos_map) ){
            biz_pos_map[naics_code] = businesses_arr.length;
            businesses_arr.push( 
              {
                'type':business['naics'],
                'directory':[]
              } 
            );
          }
          businesses_arr[ biz_pos_map[naics_code] ]['directory'].push( business );

        }
      }
    }

    // console.log(movies_arr);
    // console.log(events_arr);
    // console.log(businesses_arr);
    // console.log(food_arr);
    // console.log(drink_arr);
    // console.log(lodge_arr);
    // console.log(med_arr);
    // console.log(weed_arr);
    // console.log(market_arr);
    // console.log(pharmacy_arr);
    // console.log(barber_arr);
    // console.log(salon_arr);
    // console.log(repair_arr);

    // Update state
    const azulSet = new Set(this.props.azulPins);
    this.setState({
      show:true,
      /*favorite: this.props.azulPins.indexOf(sid) > -1 ? true : false,*/
      favorite: azulSet.has(sid),
      station: this.props.station_map[sid],
      station_id: sid,
      lines: lines_arr,
      agency: this.props.agency_map[station.agency_id],
      modal_dict: {
        favorites: favs_arr,
        lines: lines_arr.length > 2 ? lines_arr : [],
        movies: movies_arr,
        events: events_arr,
        food: food_arr,
        drink: drink_arr,
        weed: weed_arr,
        medical: med_arr,
        lodge: lodge_arr,
        market: market_arr,
        barber: barber_arr,
        salon: salon_arr,
        repair: repair_arr,
        pharmacy: pharmacy_arr,
        businesses: businesses_arr
      }
    });
  }

  handleClose() {
    this.setState({show:false});

    // Reset shuffle flag to true
    const resetShuffle = this.props.resetShuffle;
    setTimeout(function(){ resetShuffle(); }, 275);

  }

  toggleJumbo() {
    const jumbo = !this.state.jumbo;
    this.setState({jumbo});
  }

  renderMarkers() {
    const azulSet = new Set(this.props.azulPins),
    handleShow = this.handleShow;
    return this.props.stations.map((station) => (
        <PinComponent
          id={station.station_id}
          lat={station.lat}
          lng={station.long}
          className={ 
            azulSet.has(station.station_id) 
            ? 'azulPin'
            : 'negroPin'
          }
          handleShow={handleShow}
          station={station}
        />
      )
    )
  }

  // <PinComponent
  //         id={station.station_id}
  //         lat={station.lat}
  //         lng={station.long}
  //         className={ 
  //           azulSet.has(station.station_id) 
  //           ? 'azulPin'
  //           : 'negroPin'
  //         }
  //         onClick={handleShow}
  //         station={station}
  //       />

  limpiarTiempo(tiempo) {
    // console.log(tiempo);
    const tiempoArr = tiempo.split(":"),
    hora = tiempoArr[0] % 12 ? tiempoArr[0] % 12 : 12,
    minutos = tiempoArr[1],
    cambioHora = tiempoArr[0] > hora,
    incluirMinutos = minutos !== "00",
    nuevoTiempo = `${hora}${incluirMinutos ? `:${minutos}` : ''}${cambioHora ? ' PM' : ' AM'}`;
    return nuevoTiempo;
  }

  superReverse(arr) {
    const len = arr.length;
    let backwardI = len - 1;
    while (backwardI > -1) {
      arr.push(arr[backwardI]);
      arr.splice(backwardI, 1);
      backwardI--;
    }
    return arr;

  }

  tiemposAPI(lineArr) {
    const state = this.state;
    let etaTXT = state.etaTXT,
    otroTXT = state.otroTXT;
    const props = this.props,
    lineId = lineArr[0], lineName = lineArr[1],
    stationId = lineArr[2], ciudad = props.usuario.perfil.ciudad,
    showTiempo = !state.showTiempo,
    ogTXT = state.ogTXT,
    etaCHX = etaTXT !== ogTXT,
    superReverse = this.superReverse,
    limpiarTiempo = this.limpiarTiempo;
    // Check showTiempo
    if(showTiempo || etaCHX){

      // Verify text
      if(etaCHX){
        this.setState({etaTXT:ogTXT,otroTXT:''});

      } else {
        this.setState({showTiempo});

      }
      
      // API
      // console.log('POST: ',{stationId,lineId,lineName});
      axios.post("/api/tiempos",{stationId,lineId,lineName}).then((response) => {
        const resp = response.data,
        nuevos = {};
        let limpio = [],
        nArr = [],
        proximo = -1,
        despues = -1,
        nLen = -1,
        tiempoFlag = false,
        rows, row_len;
        
        // Ciudad
        // console.log(ciudad,resp);
        switch(ciudad){
          case '1':
            limpio = false;
            nuevos[0] = resp;
            break;
            
            // Reference ( old API )

            // limpio = resp.items.filter(x => x.is_departing);

            // // Restructure LA limpio array
            // rows = limpio;
            // row_len = rows.length;
            // for( var i = row_len; i--; ){
            //   const viaje = rows[i],
            //   run_id = viaje.run_id,
            //   minutos = parseInt(viaje.minutes);

            //   // Populate nuevos dict
            //   if( !(run_id in nuevos) ){
            //     nuevos[run_id] = [];
            //   }

            //   // Push to 0 or 1 array
            //   nuevos[run_id].push(minutos);

            // };
            // break;

          case '2':
            const abbr = lineName.split('(')[0].trim().split('-')[1],
            color = lineName.split('(')[1].replace(')','').trim().toUpperCase(),
            init_limpio = 'etd' in resp.root.station[0] ? resp.root.station[0].etd.filter( x => x.abbreviation === abbr ) : [];
            limpio = init_limpio.length ? init_limpio[0].estimate.filter( x => x.color === color && !parseInt(x.delay) ) : [];

            // Restructure SF limpio array
            rows = limpio;
            row_len = rows.length;
            for( var i = row_len; i--; ){
              const viaje = rows[i],
              direction = viaje.direction,
              minutos = parseInt(viaje.minutes);

              // Skip invalid values (i.e. NaN)
              if ( !(minutos) ) { continue; }

              // Populate nuevos dict
              if( !(direction in nuevos) ){
                nuevos[direction] = [];
              }

              // Push to 0 or 1 array
              nuevos[direction].push(minutos);

            };
            break;
          case '3':
            if(lineName !== 'PATH'){
              limpio = false;
              nuevos[0] = resp;

            } else {
              tiempoFlag = true;
              limpio = resp.upcomingTrains.filter(x => x.status === 'ON_TIME' ).sort(function (a, b) {
                a = a['projectedArrival'].split('T')[1];
                b = b['projectedArrival'].split('T')[1];
                return a[0] - b[0] || a[1] - b[1] || a[2] - b[2];

              });
              
              // Restructure NJ limpio array
              rows = superReverse( limpio );
              row_len = rows.length;
              for( var i = row_len; i--; ){
                
                const viaje = rows[i],
                run_id = viaje['direction'],
                minutos = limpiarTiempo(viaje['projectedArrival'].split('T')[1]);

                // Populate nuevos dict
                if( !(run_id in nuevos) ){
                  nuevos[run_id] = [];
                }

                // Push to 0 or 1 array
                nuevos[run_id].push(minutos);

              };
              
            }
            break;
          case '4':
            tiempoFlag = true;
            
            limpio = 'eta' in resp.ctatt ? resp.ctatt.eta.filter(x => !parseInt(x.isDly) && !parseInt(x.isSch)).sort(function (a, b) {
              a = a['arrT'].split('T')[1];
              b = b['arrT'].split('T')[1];
              return a[0] - b[0] || a[1] - b[1] || a[2] - b[2];

            }) : [];
            
            // Restructure CHI limpio array
            rows = superReverse( limpio );
            row_len = rows.length;
            for( var i = row_len; i--; ){
              
              const viaje = rows[i],
              run_id = viaje['trDr'],
              minutos = limpiarTiempo(viaje['arrT'].split('T')[1]);

              // Populate nuevos dict
              if( !(run_id in nuevos) ){
                nuevos[run_id] = [];
              }

              // Push to 0 or 1 array
              nuevos[run_id].push(minutos);

            };
            // case '4':
            //   limpio = false;
            //   nuevos[0] = resp;
            //   break;
            break;
          case '5':
            const shorthand = lineId.split('-')[1];
            limpio = resp['Trains'].filter(x => x['Line'] === shorthand && parseInt(x['Min']) );

            // Restructure LA limpio array
            rows = limpio;
            row_len = rows.length;
            for( var i = row_len; i--; ){
              const viaje = rows[i],
              run_id = viaje['Group'],
              minutos = parseInt(viaje['Min']);

              // Populate nuevos dict
              if( !(run_id in nuevos) ){
                nuevos[run_id] = [];
              }

              // Push to 0 or 1 array
              nuevos[run_id].push(minutos);

            };
            break;
          case '6':
            limpio = false;
            nuevos[0] = resp;
            break;
          case '7':
            limpio = false;
            nuevos[0] = resp;
            break;
          default:
            console.log('Unknown City: ',ciudad);
        }

        // Use limpio === false as NO_API flag
        if(tiempoFlag){
          // Sort and map to string
          nArr = Object.keys(nuevos);
          nLen = nArr.length ? nArr.length - 1 : false;
          proximo = !nLen ? 0 : nuevos[ nArr[0] ][0].split(' ')[0] < nuevos[ nArr[1] ][0].split(' ')[0] ? 0 : 1;
          despues = !nLen ? 0 : nuevos[ nArr[0] ][0].split(' ')[0] < nuevos[ nArr[1] ][0].split(' ')[0] ? 1 : 0;
          etaTXT = Number.isInteger(nLen) && nLen + 1 ? nuevos[ nArr[proximo] ].join(', ') : 'Results not found.';
          otroTXT = nLen ? nuevos[ nArr[despues] ].join(', ') : '';
          
        } else if(limpio){
          // Sort and map to string
          nArr = Object.keys(nuevos);
          nLen = nArr.length ? nArr.length - 1 : false;
          proximo = !nLen ? 0 : Math.min(...nuevos[ nArr[0] ]) < Math.min(...nuevos[ nArr[1] ]) ? 0 : 1;
          despues = !nLen ? 0 : Math.min(...nuevos[ nArr[0] ]) < Math.min(...nuevos[ nArr[1] ]) ? 1 : 0;
          etaTXT = Number.isInteger(nLen) && nLen + 1 ? nuevos[ nArr[proximo] ].sort((a, b) => a - b).map( x => `${x} min` ).join(', ') : 'Results not found.';
          otroTXT = nLen ? nuevos[ nArr[despues] ].sort((a, b) => a - b).map( x => `${x} min` ).join(', ') : '';

        } else {
          const vinculo = nuevos[0];
          etaTXT = `${vinculo}ALTABERIA`;
          otroTXT = '';

        }

        // Set new state
        // console.log(etaTXT,otroTXT);
        this.setState({etaTXT,otroTXT});
      }).catch((error) => {
          console.log(error);

        }
      );
    } else {
      // Reset
      etaTXT = ogTXT;
      otroTXT = '';
      this.setState({etaTXT,showTiempo,otroTXT});

    }    

  }

  renderTransitLines() {
    const tiemposAPI = this.tiemposAPI,
    state = this.state,
    lines = state.lines,
    showTiempo = state.showTiempo,
    etaTXT = state.etaTXT,
    otroTXT = state.otroTXT;
    if('lines' in state){
      return lines.map((line) => (
          <OverlayTrigger
            trigger="click"
            key="bottom"
            placement="bottom"
            overlay={
              <Popover id={`${line[0]}`} show={showTiempo}>
                <Popover.Title as="h3">{'Next trip...'}</Popover.Title>
                <Popover.Content>
                  {etaTXT.indexOf('ALTABERIA') !== -1 ? 
                    <a href={`${etaTXT.replace('ALTABERIA','')}`} target="_blank" rel="noopener noreferrer">
                      <h2>{`click here (${line[1]})`}</h2>
                    </a> 
                    : etaTXT
                  }
                  {otroTXT && [<hr/>,
                    <h2>Other direction:</h2>,
                    otroTXT
                  ]}
                </Popover.Content>
              </Popover>
            }
            rootClose
          >
            <Badge pill variant="dark" className="line_badge" onClick={() => tiemposAPI(line)}>
              {`${line[1]} `}<i class="fas fa-hand-pointer"></i>
            </Badge>
          </OverlayTrigger>
        )
      )
    }
  }

  renderModal() {
    // State
    const state = this.state,
    show = state.show,
    handleClose = this.handleClose,
    station_id = state.station_id,
    station = state.station,
    lines = state.lines,
    renderTransitLines = this.renderTransitLines,
    modal_dict = state.modal_dict,
    favorite = state.favorite,
    etaTXT = state.etaTXT,
    otroTXT = state.otroTXT,
    showTiempo = state.showTiempo,
    tiemposAPI = this.tiemposAPI,
    // Props
    props = this.props,
    shuffle_flag = props.shuffle_flag,
    handleFavorite = props.handleFavorite,
    handleUnfavorite = props.handleUnfavorite,
    favorites = props.favorites,
    usuario_tabs = props.usuario.perfil.tabs,
    quitarMuchosFavs = props.quitarMuchosFavs;
    return <Modal size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
      id='mobileModal'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {station} {'lines' in state && lines.length < 3 && renderTransitLines()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { show && <TabsComponent 
            modal_dict={modal_dict}
            shuffle_flag={shuffle_flag}
            handleFavorite={handleFavorite}
            handleUnfavorite={handleUnfavorite}
            favorites={favorites}
            usuario_tabs={usuario_tabs}
            tiemposAPI={tiemposAPI}
            etaTXT={etaTXT}
            otroTXT={otroTXT}
            showTiempo={showTiempo}
          />
        }
      </Modal.Body>
      <Modal.Footer>
      { favorite && modal_dict.favorites.length ?
        <img
          className="d-block footer"
          src={happycone}
          alt="Happy Cone"
          onClick={() => quitarMuchosFavs(station_id)}
        />
      : <img
          className="d-block footer"
          src={sadcone}
          alt="Sad Cone"
        />
      }

      <span className="footer">
        { favorite && modal_dict.favorites.length ? `${modal_dict.favorites.length}x Favorites!` : 'No favorites...' }
      </span>

      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      </Modal.Footer>
    </Modal>
  }
 
  render() {
    // console.log("GoogleMap: ",this.props)
    const state = this.state,
    jumbo = state.jumbo,
    toggleJumbo = this.toggleJumbo,
    center = state.center,
    zoom = state.zoom,
    styles = state.styles,
    renderModal = this.renderModal,
    renderMarkers = this.renderMarkers,
    // Props
    props = this.props,
    perfil = props.usuario.perfil,
    updatePerfil = props.updatePerfil,
    azulPins = props.azulPins,
    quitarMuchosFavs = props.quitarMuchosFavs,
    openFAQs = props.openFAQs,
    handleLogout = props.handleLogout;
    return (
      // Important! Always set the container height explicitly
      // AIzaSyBP3Xb01OSpLPBryCTei3tja3b8pU90oIg
      <div style={{ height: '100%', width: '100%' }}>
        {jumbo &&
          <UserForm 
            perfil={perfil}
            updatePerfil={updatePerfil}
            toggleJumbo={toggleJumbo}
            azulPins={azulPins}
            quitarMuchosFavs={quitarMuchosFavs}
          />
        }
        <Button variant="secondary" className="logout" onClick={handleLogout}>
          Logout
        </Button>
        <div onClick={toggleJumbo} className="userIcon">
          <i class="fas fa-user-cog fa-2x"></i>
        </div>
        <div onClick={openFAQs} id="helpFAQs" className="userIcon">
          <i className="fas fa-question fa-2x"></i>
        </div>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBvS5vS3Dm9tHtoIZcW87G_Pd_tDoBz0os"}}
          defaultCenter={center}
          defaultZoom={zoom}
          layerTypes={['TransitLayer']}
          options={{styles: styles, clickableIcons: false}}
        >
          {renderMarkers()}
        </GoogleMapReact>
        {renderModal()}
      </div>
    );
  }
}
 
export default GoogleMap;