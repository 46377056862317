import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import CarouselComponent from './CarouselComponent';
import { ad_list } from './adList';

class TabsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			advertisers: ad_list,
			escondidos: true,
			escondidosArr: ['food','drink','lodge','barber','salon','medical','repair','weed','pharmacy','market'],
			mas_menos: false,
			mas_menos_TXT: 'More > >',
			busqueda: ''
		}
		// Binding getQuotes to our component since we'll be passing this
		// method to child components
		this.shuffle = this.shuffle.bind(this);
		this.insertAds = this.insertAds.bind(this);
		this.superInsert = this.superInsert.bind(this);
		this.getRandomInt = this.getRandomInt.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.toggleEscondidos = this.toggleEscondidos.bind(this);
		this.toggleMasMenosTXT = this.toggleMasMenosTXT.bind(this);
		this.getRandomAdvertiser = this.getRandomAdvertiser.bind(this);
	    this.construirRegEx = this.construirRegEx.bind(this);
	    this.nuevosResultados = this.nuevosResultados.bind(this);
	    this.actualizarBusqueda = this.actualizarBusqueda.bind(this);
	    this.nullifyBusqueda = this.nullifyBusqueda.bind(this);
	}

	componentDidMount() {
		// console.log('TABS: ',this);

		// Variables
		const insertAds = this.insertAds,
		usuario_tabs = this.props.usuario_tabs,
		favs = this.props.modal_dict.favorites,
		lines = this.props.modal_dict.lines,
		events = this.props.modal_dict.events,
		movies = this.props.modal_dict.movies,
		food = insertAds(this.props.modal_dict.food),
		drink = insertAds(this.props.modal_dict.drink), 
		weed = insertAds(this.props.modal_dict.weed),
		medical = insertAds(this.props.modal_dict.medical),
		lodge = insertAds(this.props.modal_dict.lodge),
		market = insertAds(this.props.modal_dict.market),
		pharmacy = insertAds(this.props.modal_dict.pharmacy), 
		salon = insertAds(this.props.modal_dict.salon),
		repair = insertAds(this.props.modal_dict.repair),
		barber = insertAds(this.props.modal_dict.barber),
		businesses = insertAds(this.props.modal_dict.businesses),
		// Lengths
		favs_len = favs.length,
		lines_len = lines.length,
		events_len = events.length,
		movies_len = movies.length,
		food_len = food.length,
		drink_len = drink.length,
		weed_len = weed.length,
		med_len = medical.length,
		lodge_len = lodge.length,
		market_len = market.length,
		salon_len = salon.length,
		barber_len = barber.length,
		pharmacy_len = pharmacy.length,
		repair_len = repair.length,
		biz_len = businesses.length,
		// Default Flags
		favs_flag = favs_len > 0,
		lines_flag = lines_len > 0,
		biz_flag = biz_len > 0,
		// Option Flags
		movie_flag = usuario_tabs.indexOf('movie') > -1 && movies_len > 0,
		event_flag = usuario_tabs.indexOf('event') > -1 && events_len > 0,
		// Option Flags (escondidos)
		food_flag = usuario_tabs.indexOf('food') > -1 && food_len > 0,
		drink_flag = usuario_tabs.indexOf('drink') > -1 && drink_len > 0,
		lodge_flag = usuario_tabs.indexOf('lodge') > -1 && lodge_len > 0,
		barber_flag = usuario_tabs.indexOf('barber') > -1 && barber_len > 0,
		salon_flag = usuario_tabs.indexOf('salon') > -1 && salon_len > 0,
		med_flag = usuario_tabs.indexOf('medical') > -1 && med_len > 0,
		repair_flag = usuario_tabs.indexOf('repair') > -1 && repair_len > 0,
		weed_flag = usuario_tabs.indexOf('weed') > -1 && weed_len > 0,
		pharmacy_flag = usuario_tabs.indexOf('pharmacy') > -1 && pharmacy_len > 0,
		market_flag = usuario_tabs.indexOf('market') > -1 && market_len > 0,
		// tabCount
		tabCount = [
			favs_flag,
			lines_flag,
			biz_flag,
			food_flag,
			drink_flag,
			lodge_flag,
			pharmacy_flag,
			barber_flag,
			salon_flag,
			market_flag,
			med_flag,
			repair_flag,
			weed_flag,
			movie_flag,
			event_flag
		].filter(v => v).length,
		expand_flag = tabCount < 7,
		// Active key
		active_key = favs_flag ? 'favs' 
		: lines_flag ? 'lines' 
		: movie_flag ? 'movies' 
		: event_flag ? 'events'
		: !expand_flag ? 'busqueda'
		: food_flag ? 'food' 
		: drink_flag ? 'drink' 
		: lodge_flag ? 'lodge'
		: weed_flag ? 'weed'
		: pharmacy_flag ? 'pharmacy' 
		: barber_flag ? 'barber' 
		: salon_flag ? 'salon' 
		: market_flag ? 'market' 
		: med_flag ? 'medical' 
		: repair_flag ? 'repair' 
		: biz_flag ? 'businesses'
		: 'default',
		// Init
		init_state = {
			favs,
			lines,
			events,
			movies,
			food,
			drink,
			weed,
			medical,
			lodge,
			market,
			pharmacy,
			barber,
			salon,
			repair,
			businesses,
			favs_flag,
			lines_flag,
			biz_flag,
			food_flag,
			drink_flag,
			weed_flag,
			med_flag,
			lodge_flag,
			market_flag,
			barber_flag,
			salon_flag,
			repair_flag,
			pharmacy_flag,
			movie_flag,
			event_flag,
			active_key
		};
		// Validate active_key & remove escondidos tab if default
		if(active_key === 'default' || expand_flag){
			init_state['escondidos'] = false;
		} else {
			init_state['mas_menos'] = true;
		}
		// Set initial state
		init_state['og_active_key'] = active_key;
		this.setState(init_state);

	}

	nullifyBusqueda(){
		const busqueda = '';
		this.setState({busqueda})
	}

	actualizarBusqueda(event) {
		const target = event.target,
		name = target.name;
		let value;

		// Check field name
		switch(name) {
			case 'busqueda':
				value = target.value
				break;
			default:
				value = ''
		}

		// Update state
		this.setState({
			[name]: value
		});
	}

	construirRegEx() {
		const busqueda = this.state.busqueda.replace(/\s+/g,' ').trim().toLowerCase(),
		base_string = busqueda.length > 2 ? busqueda.split('||').map( sub => sub.indexOf('&&') > -1 ? sub.split('&&').map( s => `(?=.*\\b${s.trim()}\\b)` ).join('') : `(?=.*\\b${sub.trim()}\\b)` ).join('|')
		: '', regex_string = base_string ? `^${base_string}.*$` : false;
		return regex_string;
	}

	/*actualizarResultados(event) {
		event.preventDefault();*/
	nuevosResultados() {
		const regex_string = this.construirRegEx(),
		isMatch = regex_string ? new RegExp(regex_string,'g') : regex_string,
		modal_dict = this.props.modal_dict;
		let resultados = [], resultadosSet = new Set([]), rows, row_len,
		rows2, row_len2,
		rows3, row_len3;

		// Check if busqueda match
		const todosArr = Object.keys( modal_dict );

		// Return empty array is invalid busqueda
		if(isMatch){

			// Restructure lines array
			rows = todosArr;
			row_len = rows.length;
			for( var i = row_len; i--; ){

				const tmpDataType = rows[i],
				tmpDataArr = modal_dict[tmpDataType],
				tmpDataArrLen = tmpDataArr.length,
				directory_flag = typeof tmpDataArr[0] !== 'string' && tmpDataArrLen && 'directory' in tmpDataArr[0];

				// CONFIRM DATA EXISTS
	        	if ( !tmpDataArrLen ) { continue; }

				// Restructure temporary data array
				rows2 = tmpDataArr;
				row_len2 = rows2.length;
				for( var j = row_len2; j--; ){
					const tmpRow = rows2[j],
					tmpName = directory_flag ? 'directory' 
					: typeof tmpDataArr[0] === 'string' ? false
					: 'dba_name' in tmpRow ? tmpRow.dba_name.toLowerCase() : false;

					// CONFIRM dba_name EXISTS
	        		if ( !(tmpName) ) { continue; }

	        		// Check for directory
	        		if (tmpName === 'directory'){
	        			// Loop through directories && compare to existing
					    rows3 = tmpRow.directory;
					    row_len3 = rows3.length;
					    for( var k = row_len3; k--; ){
					    	const tmpDirRow = rows3[k],
					    	tmpDirName = 'dba_name' in tmpDirRow ? tmpDirRow.dba_name.toLowerCase() : false;

							// CONFIRM dba_name EXISTS
							if ( !(tmpDirName) ) { continue; }

							// Verify match
							if( tmpDirName.match(isMatch) && !resultadosSet.has(tmpDirRow.id) ){

								// Push to resultados array
								resultadosSet.add(tmpDirRow.id)
								resultados.push(tmpDirRow)

							}

					    };

	        		} else if( tmpName.match(isMatch) && !resultadosSet.has(tmpRow.id) ){

						// Push to resultados array
						resultadosSet.add(tmpRow.id)
						resultados.push(tmpRow)

					}

				};

			};
		}

        // Insert ads & Update state
        // console.log(resultadosSet);
		resultados = this.superInsert(resultados);
		// console.log(resultados);
		// Only return first 250 resuts
		return resultados.slice(0,250);

	}

	handleSelect(active_key) {
		if(active_key === 'mas_menos'){
			this.toggleEscondidos();
		} else {
			this.setState({active_key});
		}
	}

	toggleEscondidos() {
		const escondidos = this.state.escondidos,
		mas_menos_TXT = escondidos ? 'expanding...' : 'compressing...',
		toggleMasMenosTXT = this.toggleMasMenosTXT;
		this.setState({mas_menos_TXT},
			toggleMasMenosTXT
		);
	}

	toggleMasMenosTXT() {
		const escondidos = !this.state.escondidos,
		tmp_active_key = this.state.active_key,
		active_key = this.state.escondidosArr.indexOf(tmp_active_key) > -1 ? this.state.og_active_key : tmp_active_key,
		mas_menos_TXT = escondidos ? 'More > >' : '< < Less';
		// Timeout to fake API call
		setTimeout(() => { this.setState({escondidos,active_key,mas_menos_TXT}); }, 550);
	}

	shuffle(arr) {

		if(this.props.shuffle_flag){
			let currentIndex = arr.length, temporaryValue, randomIndex;
			// While there remain elements to shuffle...
			while (0 !== currentIndex) {

				// Pick a remaining element...
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex -= 1;

				// And swap it with the current element.
				temporaryValue = arr[currentIndex];
				arr[currentIndex] = arr[randomIndex];
				arr[randomIndex] = temporaryValue;
			}

			// console.log("SHUFFLE: ",arr);

			return arr;
		} else {
			return arr;
		}
	}

	getRandomInt() {
		// console.log("RANDINT");
	  	return Math.floor(Math.random() * 3) + 1;
	}

	getRandomAdvertiser() {
	  	return this.state.advertisers[Math.floor(Math.random() * this.state.advertisers.length)];
	}

	superInsert(mArr){
		// Restructure modal array (shuffle if not string--strings mostly sorted datetime strings)
		const tmp_arr = typeof mArr[0] != 'string' ? this.shuffle(mArr) : mArr,
		tmp_len = tmp_arr.length,
		getRandomInt = this.getRandomInt,
		getRandomAdvertiser = this.getRandomAdvertiser;
		let tmp_sum = 0, index_arr = [];
		while(tmp_sum < tmp_len){
			tmp_sum += getRandomInt();
			index_arr.push(tmp_sum);
		}

		// Construct index array for ads population
		const index_sum = index_arr.reduce(function(a, b){return a + b;},0),
		index_len = index_arr.length;
		if(index_sum + index_len > tmp_len){
			// console.log(index_arr,"SLICE?");
			index_arr = index_arr.slice(0,index_len - 1)
			// console.log(index_arr,"SLICE!");
		}

		// Default to ONE ads if only one biz
		if(index_arr.length < 2){
			const ads_flag = getRandomAdvertiser();
			tmp_arr.push({ads_flag});
		}

		// Loop through index_arr and populate ads into tmp_arr
		const rows = index_arr,
		row_len = rows.length;
		for( var i = row_len; i--; ){
			const idx = rows[i];

			// Slice 'ads' into tmp_arr
			const ads_flag = getRandomAdvertiser();
			tmp_arr.splice(idx, 0, {ads_flag});

		};

		// Return randomized and ads-populate array
		// console.log(tmp_arr);
		return tmp_arr;

	}

	insertAds(modal_arr){
		const superInsert = this.superInsert;

		if(modal_arr.length && this.props.shuffle_flag){

			// Must check if not string or 'in' will fail
			const directory_flag = typeof modal_arr[0] != 'string' && 'directory' in modal_arr[0];
			let new_arr;

			if(directory_flag) {

				// Shuffle Categories & Directories
				modal_arr = this.shuffle(modal_arr);
				new_arr = modal_arr.map((mObj) => ({
						type:mObj.type,
						// Insert Ads
						directory:superInsert(mObj.directory)
					})
				);

			} else {

				// Insert Ads
				new_arr = superInsert(modal_arr);

			}

			// Return randomized and ads-populate array
			return new_arr;
		} else {
			return modal_arr

		}
	}

	render() {
		const insertAds = this.insertAds,
		actualizarBusqueda = this.actualizarBusqueda,
		nuevosResultados = this.nuevosResultados,
		nullifyBusqueda = this.nullifyBusqueda,
		handleSelect = this.handleSelect,
		// busquedaSet = new Set(['events','movies','default']),
		busquedaSet = new Set(['default']),
		// State
		state = this.state,
		active_key = state.active_key,
		og_active_key = state.og_active_key,
		favs_flag = state.favs_flag,
		favs = state.favs,
		escondidos = state.escondidos,
		lines_flag = state.lines_flag,
		lines = state.lines,
		resultados = state.resultados,
		busqueda = state.busqueda,
		food_flag = state.food_flag,
		food = state.food,
		drink_flag = state.drink_flag,
		drink = state.drink,
		lodge_flag = state.lodge_flag,
		lodge = state.lodge,
		pharmacy_flag = state.pharmacy_flag,
		pharmacy = state.pharmacy,
		barber_flag = state.barber_flag,
		barber = state.barber,
		salon_flag = state.salon_flag,
		salon = state.salon,
		market_flag = state.market_flag,
		market = state.market,
		med_flag = state.med_flag,
		medical = state.medical,
		repair_flag = state.repair_flag,
		repair = state.repair,
		weed_flag = state.weed_flag,
		weed = state.weed,
		biz_flag = state.biz_flag,
		businesses = state.businesses,
		event_flag = state.event_flag,
		events = state.events,
		movie_flag = state.movie_flag,
		movies = state.movies,
		mas_menos = state.mas_menos,
		mas_menos_TXT = state.mas_menos_TXT,
		// Props
		props = this.props,
		favorites = props.favorites,
		handleFavorite = props.handleFavorite,
		handleUnfavorite = props.handleUnfavorite,
		tiemposAPI = props.tiemposAPI,
		showTiempo = props.showTiempo,
		etaTXT = props.etaTXT,
		otroTXT = props.otroTXT;
		// console.log(busquedaSet.has(active_key),busquedaSet,active_key);
		return (
			<Tabs activeKey={active_key} id="modal_tabs" onSelect={handleSelect}>
				{favs_flag && (<Tab eventKey="favs" title="Favorites">
					<CarouselComponent 
						data_type={"favorites"}
						modal_arr={favs} 
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{lines_flag && (<Tab eventKey="lines" title="Lines">
					<CarouselComponent 
						data_type={"lines"}
						modal_arr={lines}
						tiemposAPI={tiemposAPI}
						showTiempo={showTiempo}
						etaTXT={etaTXT}
						otroTXT={otroTXT}
					/>
				</Tab>)}
				{!busquedaSet.has(og_active_key) && (<Tab eventKey="busqueda" title="Search">
					<CarouselComponent 
						data_type={"resultados"}
						modal_arr={resultados}
						favorites={favorites}
						busqueda={busqueda}
						nullifyBusqueda={nullifyBusqueda}
						actualizarBusqueda={actualizarBusqueda}
						nuevosResultados={nuevosResultados}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{movie_flag && (<Tab eventKey="movies" title="Movies">
					<CarouselComponent 
						data_type={"movies"}
						modal_arr={movies}
						insertAds={insertAds}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{event_flag && (<Tab eventKey="events" title="Events">
					<CarouselComponent 
						data_type={"events"}
						modal_arr={events}
						insertAds={insertAds}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{biz_flag && (<Tab eventKey="businesses" title="Local">
					<CarouselComponent 
						data_type={"local"}
						modal_arr={businesses}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{!escondidos && food_flag && (<Tab eventKey="food" title="Food">
					<CarouselComponent 
						data_type={"food"}
						modal_arr={food}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{!escondidos && drink_flag && (<Tab eventKey="drink" title="Drink">
					<CarouselComponent 
						data_type={"drink"}
						modal_arr={drink}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{!escondidos && lodge_flag && (<Tab eventKey="lodge" title="Lodge">
					<CarouselComponent 
						data_type={"lodge"}
						modal_arr={lodge}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite} 
					/>
				</Tab>)}
				{!escondidos && pharmacy_flag && (<Tab eventKey="pharmacy" title="Pharmacy">
					<CarouselComponent 
						data_type={"pharmacy"}
						modal_arr={pharmacy}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{!escondidos && barber_flag && (<Tab eventKey="barber" title="Barber">
					<CarouselComponent 
						data_type={"barber"}
						modal_arr={barber}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{!escondidos && salon_flag && (<Tab eventKey="salon" title="Salon">
					<CarouselComponent 
						data_type={"salon"}
						modal_arr={salon}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{!escondidos && market_flag && (<Tab eventKey="market" title="Market">
					<CarouselComponent 
						data_type={"market"}
						modal_arr={market}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite} 
					/>
				</Tab>)}
				{!escondidos && med_flag && (<Tab eventKey="medical" title="Medical">
					<CarouselComponent 
						data_type={"medical"}
						modal_arr={medical}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite} 
					/>
				</Tab>)}
				{!escondidos && repair_flag && (<Tab eventKey="repair" title="Repair">
					<CarouselComponent 
						data_type={"repair"}
						modal_arr={repair}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{!escondidos && weed_flag && (<Tab eventKey="weed" title="Weed">
					<CarouselComponent 
						data_type={"weed"}
						modal_arr={weed}
						favorites={favorites}
						handleFavorite={handleFavorite}
						handleUnfavorite={handleUnfavorite}
					/>
				</Tab>)}
				{active_key === 'default' && (<Tab eventKey="default" title="404">
					<div className="boleto">
						{"Empty :("}
						<hr/>
						Nothing goin' on around here!
					</div>
				</Tab>)}
				{mas_menos && (
					<Tab 
						eventKey="mas_menos" 
						title={mas_menos_TXT}
					/>
				)}
			</Tabs>
		);
	}
}

export default TabsComponent;