import React, { Component } from 'react';
import happycone from '../img/happycone.png';

class HelpFAQs extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  fa_user_cog: false,
                  fa_question: false,
                  fa_file_contract: false,
                  fa_user_secret: false,
                  fa_dot_circle: false,
                  happy_cone: false
            }
            this.toggleUser = this.toggleUser.bind(this);
            this.toggleQuestion = this.toggleQuestion.bind(this);
            this.toggleContract = this.toggleContract.bind(this);
            this.toggleSecret = this.toggleSecret.bind(this);
            this.toggleCircle = this.toggleCircle.bind(this);
            this.toggleHappy = this.toggleHappy.bind(this);
      }

      toggleUser() {
            const fa_user_cog = !this.state.fa_user_cog;
            this.setState({fa_user_cog});
      }

      toggleQuestion() {
            const fa_question = !this.state.fa_question;
            this.setState({fa_question});
      }

      toggleContract() {
            const fa_file_contract = !this.state.fa_file_contract;
            this.setState({fa_file_contract});
      }

      toggleSecret() {
            const fa_user_secret = !this.state.fa_user_secret;
            this.setState({fa_user_secret});
      }

      toggleCircle() {
            const fa_dot_circle = !this.state.fa_dot_circle;
            this.setState({fa_dot_circle});
      }

      toggleHappy() {
            const happy_cone = !this.state.happy_cone;
            this.setState({happy_cone});
      }

      render() {
            const state = this.state,
            fa_user_cog = state.fa_user_cog,
            fa_question = state.fa_question,
            fa_file_contract = state.fa_file_contract,
            fa_user_secret = state.fa_user_secret,
            fa_dot_circle = state.fa_dot_circle,
            happy_cone = state.happy_cone,
            toggleUser = this.toggleUser,
            toggleQuestion = this.toggleQuestion,
            toggleContract = this.toggleContract,
            toggleSecret = this.toggleSecret,
            toggleCircle = this.toggleCircle,
            toggleHappy = this.toggleHappy;
            return (
                  <div className="TCPrivacy" id="ayuda">
                        <h1>Welcome to our Frequently Asked Questions</h1>
                        <h3>Your questions are critically important to us.</h3>
                        TransitMigo is located at:<br/>
                        <address>
                          www.transitmigo.com<br/>
                          <br/>
                        </address>

                        <h2>User Settings</h2>
                        <p onClick={toggleUser}>
                              <i class="fas fa-user-cog fa-3x"></i>
                        </p>
                        { fa_user_cog && <p>Toggle between cities, select default Tabs, and bulk Favorite / Unfavorite local businesses nearby transit stops!</p> }
                        <br/>
                        <br/>

                        <h2>FAQs</h2>
                        <p onClick={toggleQuestion}>
                              <i class="fas fa-question fa-3x"></i>
                        </p>
                        { fa_question && <p>You're in it! Short and sweet explanation of how to get around TransitMigo.</p> }
                        <br/>
                        <br/>

                        <h2>Terms & Conditions</h2>
                        <p onClick={toggleContract}>
                              <i class="fas fa-file-contract fa-3x"></i>
                        </p>
                        { fa_file_contract && <p>Sorry amigo! There's laws around here in TransitMigo, so we encourage you to take a look for details on things such as cookies and using TransitMigo content.</p> }
                        <br/>
                        <br/>

                        <h2>Privacy Policy</h2>
                        <p onClick={toggleSecret}>
                              <i class="fas fa-user-secret fa-3x"></i>
                        </p>
                        { fa_user_secret && <p>TransitMigo aims to respect your privacy concerns regarding any information we may use. Our Privacy Policy includes information about how to request the removal of content and many other things so we encourage you to check it out!</p> }
                        <br/>
                        <br/>

                        <h2>Transit Stops</h2>
                        <p onClick={toggleCircle}>
                              <i class="fas fa-dot-circle fa-3x"></i>
                        </p>
                        { fa_dot_circle && <p>Clickable symbol representing a transit stop. Search local businesses and toggle between available nearby events and movies!</p> }
                        <br/>
                        <br/>

                        <h2>Favorite / Unfavorite</h2>
                        <p onClick={toggleHappy}>
                              <img
                                    className="d-block BizDiv"
                                    src={happycone}
                                    alt="Happy Cone"
                              />
                        </p>
                        { happy_cone && <p>Favorite or Unfavorite local businesses nearby transit stops. Find them again when you log back on later!</p> }
                        <br/>
                        <br/>
                  </div>
            );
      }
}

export default HelpFAQs;