const ad_list = [
  'LEGO',
  // 'NEW_AGE',
  'PURE_VPN',
  // 'NONI',
  // 'SKY_MOMENT',
  'DOLLAR',
  'THRIFTY',
  // 'VETERANS',
  'EVISU',
  'ORLY',
  // 'TASCHEN',
  // 'GWENISS',
  'COLTORTI',
  'MARIMEKKO',
  'ORGANIC_BABY',
  'BOWLERS_MART',
  // 'DIAMOND_CANDLES',
  'PRO_SUPPS',
  'PRIMITIVE_SKATE',
  // 'SMARTER_LOANS',
  'WANDER_BEAUTY',
  'SIMPLE_CANVAS',
  'BAKED_MELISSA',
  'OUTDOOR_COOKING',
  'REAL_KETONES',
  // 'WAFT',
  'WINDWEATHER',
  // 'MAGIC_CABIN',
  'LEAM',
  'HEARTHSONG',
  // 'TRAILER_PARTS',
  // 'GVM',
  'FLORSHEIM',
  // 'HANAH',
  'HELM',
  'NUNN',
  // 'OYNB',
  'STACY_ADAMS',
  'STYLEVANA',
  'MAGIC_KITCHEN',
  'MASTER_DYNAMIC',
  'PLOW_HEARTH',
  // 'FANCY_SPRINKLES',
  '1866',
  'BUGATCHI',
  'NOIZE_US',
  // '0CM',
  // 'DOCKATOT',
  // 'GIFTFACE',
  'LIFE_HOME',
  'STUDENT_BEANS',
  'JETSON',
  'VELOSOCK',
  'MAISON_FASHION',
  'DESIRE_MAYA',
  'BN3TH',
  // 'FRANK_PLAN',
  'ANKER',
  // 'CHAADS',
  'DRESSBARN',
  'PONDEN',
  // 'DIS',
  'GATE_DIRECT',
  'IVACY',
  'NORD',
  'QATAR',
  'SMARTY_PARTY',
  // 'CHIROMATIC',
  // 'PUTTERBALL',
  // 'TRESTIQUE',
  'ASTTERIA',
  'TEMPTATION_CRUISE',
  'CURBCHILDREN_NOVEL',
  'LOSTOKYO_SHIRTS',
  'LOSTOKYO_HATS',
  'LOSTOKYO_HOODIES',
  'SEVENPROXY_PINS'
]

export { ad_list };