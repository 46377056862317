import React, { Component } from 'react';
import { Modal, Jumbotron, Button, Container, Row, Col } from 'react-bootstrap'
import axios from "axios";
import GoogleMap from './child/GoogleMap';
import NekoLoader from './child/NekoLoader';
import PrivacyPolicy from './child/PrivacyPolicy';
import TermsConditions from './child/TermsConditions';
import HelpFAQs from './child/HelpFAQs';
import './css/App.css';
import './css/mobile.css';
// import googlelogo from './img/googlelogo.png';
import linkedinlogo from './img/linkedinlogo.png';
import steamlogo from './img/steamlogo.png';
import twitterlogo from './img/twitterlogo.png';
import facebooklogo from './img/facebooklogo.png';
import spotifylogo from './img/spotifylogo.png';
import neko from './img/neko.png';
import { stringify, parse } from 'zipson';

const LoginHome = () => <Container>
  <img
    className="d-block mw-75 mh-75 centerImg"
    src={neko}
    alt="Neko"
  />
  <Jumbotron className="login">
    <h1 className="login">Please login:</h1>
    <hr/>
    <Row>
      <Col>
        <a href="/auth/steam">
          <Button variant="light">
            <img src={steamlogo} className="buttonlogo" alt="Steam Logo"/>
          </Button>
        </a>
      </Col>
      <Col>
        <a href="/auth/linkedin">
          <Button variant="light">
            <img src={linkedinlogo} className="buttonlogo" alt="LinkedIn Logo"/>
          </Button>
        </a>
      </Col>
    </Row>
    <br/>
    <Row>
      <Col>
        <a href="/auth/facebook">
          <Button variant="light">
            <img src={facebooklogo} className="buttonlogo" alt="Facebook Logo"/>
          </Button>
        </a>
      </Col>
      <Col>
        {/*<a href="/auth/twitter?nativo=android&secreto=altaberia&app_name=javaradr&response_type=token&redirect_uri=http://localhost:8080/auth/twitter/return">*/}
        <a href="/auth/twitter">
          <Button variant="light">
            <img src={twitterlogo} className="buttonlogo" alt="Twitter Logo"/>
          </Button>
        </a>
      </Col>
      <Col>
        <a href="/auth/spotify">
          <Button variant="light">
            <img src={spotifylogo} className="buttonlogo" alt="Spotify Logo"/>
          </Button>
        </a>
      </Col>
    </Row>
    <br/>
    <Row>
      <Col>
        <a href="/auth/huesped">
          <Button variant="light">
            CONTINUE AS GUEST
          </Button>
        </a>
      </Col>
    </Row>
  </Jumbotron>
</Container>
 
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TCPrivacy: false,
      tcp_key: 'terms_and_conditions'
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.renderGoogle = this.renderGoogle.bind(this);
    this.resetShuffle = this.resetShuffle.bind(this);
    this.updatePerfil = this.updatePerfil.bind(this);
    this.updateFavorites = this.updateFavorites.bind(this);
    this.handleFavorite = this.handleFavorite.bind(this);
    this.handleUnfavorite = this.handleUnfavorite.bind(this);
    this.compareTabs = this.compareTabs.bind(this);
    this.processFavs = this.processFavs.bind(this);
    this.quitarMuchosFavs = this.quitarMuchosFavs.bind(this);
    this.renderTCPrivacy = this.renderTCPrivacy.bind(this);
    this.closeTCPrivacy = this.closeTCPrivacy.bind(this);
    this.openPrivacy = this.openPrivacy.bind(this);
    this.openTC = this.openTC.bind(this);
    this.openFAQs = this.openFAQs.bind(this);
  }

  componentDidMount() {
    const uno = axios.get("/api/init_state"),
    dos = axios.get("/users/favorites"),
    currentTime = new Date();
    let init_state, minuteDiff = 1000;
    // Spread get requests
    axios.all([uno,dos]).then(axios.spread((...responses) => {
      init_state = responses[0].data;

      // PATH variables
      let pathname = window.location.pathname;
      const valid_path_set = new Set(['/privacy','/terms','/help','/faqs']);

      // Check if needs parsing
      if(typeof init_state === 'string'){
        init_state = parse(responses[0].data);
      }

      // Restructure responses for rendering if 'stations' have populated
      if ( 'stations' in init_state ) {
        const favs = responses[1].data.favorites;
        init_state = this.processFavs(favs,init_state);

      }

      //
      // TEST
      //
      // console.log('SPREAD: ',responses);
      // console.log('INIT_STATE: ',init_state);

      // Check if USER exists
      if( 'usuario' in init_state ) {
        const createdAt = new Date(init_state.usuario.createdAt);
        minuteDiff = ((currentTime - createdAt) / 1000) / 60
      }

      // Confirm if NEW user or GUEST
      if(minuteDiff < 3) {
        pathname = '/help'
      };

      // Parse PATH
      switch(pathname){
        case '/privacy':
          init_state['tcp_key'] = 'privacy_policy';
          break;
        case '/help':
          init_state['tcp_key'] = 'help_faqs';
          break;
        case '/faqs':
          init_state['tcp_key'] = 'help_faqs';
          break;
        default:
          init_state['tcp_key'] = 'terms_and_conditions';
      }

      // Populate flags
      init_state['TCPrivacy'] = valid_path_set.has(pathname);
      init_state['shuffle_flag'] = true;
      
      // Set state
      this.setState( init_state );

    })).catch((error) => {
      console.log(error);

    });

  }

  compareTabs(arr2){
    const arr1 = this.state.usuario.perfil.tabs;
    // console.log(arr1,arr2);
    // Compare length
    if(arr1.length !== arr2.length) {
      return false;

    } else {
      const a1 = arr1.sort(),
      a2 = arr2.sort();
      let check = false,
      row_len = arr1.length;

      // Compare contents
      for( var i = row_len; i--; ){
        const val1 = arr1[i],
        val2 = arr2[i];

        if(val1 !== val2) {
          return false;
        }

      };
      return true;

    }
  }

  updatePerfil(perfil) {
    // console.log('APP! updatePerfil: ', perfil);
    const nuevaCiudad = perfil.ciudad !== this.state.usuario.perfil.ciudad ? true : false,
    newTabs = !this.compareTabs(perfil.tabs);

    // Check if API call needed
    if(nuevaCiudad) {

      // POST request & set to loading page
      this.setState({ loading: true });
      axios.post("/api/init_state",{ perfil }).then((response) => {
        // console.log('AXIOS CIUDAD: ',response.data,'THIS: ', this);

          const init_state = parse(response.data);
          init_state['shuffle_flag'] = true;
          init_state['loading'] = false;
          this.setState(init_state);

        }).catch((error) => {
          console.log(error);

        }
      );

    } else if(newTabs) {

      // POST request
      axios.post("/users/perfil",{ perfil }).then((response) => {
        // console.log('AXIOS PERFIL: ',response.data,'THIS: ', this );

        const nuevo_perfil = response.data.perfil,
        usuario = parse(stringify(this.state.usuario)),
        shuffle_flag = true;
        usuario.perfil = nuevo_perfil;
        this.setState({ usuario, shuffle_flag });

      }).catch((error) => {
        console.log(error);

      });

    }

    // console.log("END! updatePerfil: ", this);
    
  }

  processFavs(favs,obj) {
    obj['favorites_map'] = {};
    obj['favorites'] = {};
    obj['azulPins'] = new Set([]);

    // Construct favorites map & id list
    const rows = favs;
    let row_len = rows.length;
    for(var i = row_len-1; i>=0; i--){
      const datos = rows[i],
      datosId = datos.dataId,
      sid = datos.stationId;

      // Populate favorites key
      if( !(sid in obj['favorites']) ){
        obj['favorites'][sid] = []
      }

      // Populate map & list
      obj['favorites_map'][datosId] = obj;
      obj['favorites'][sid].push(datosId);
      obj['azulPins'].add(sid);

    }
    // Convert to array to save memory
    obj['azulPins'] = Array.from(obj['azulPins']);
    return obj;
  }

  updateFavorites(favs) {

    // Process favorites & Populate flags
    const shuffle_flag = false,
    new_state = this.processFavs(favs,{});
    new_state['shuffle_flag'] = shuffle_flag;

    // Set State
    this.setState(new_state);

  }

  resetShuffle() {
    this.setState({shuffle_flag:true});
  }

  quitarMuchosFavs(stationId){
    // console.log(stationId);
    const dondeObj = stationId ? {stationId} : {},
    updateFavorites = this.updateFavorites,
    confirmar = window.confirm(`Seguro amigo? You're about to bulk delete all ${stationId ? "this station's" : 'your'} favorites!`);
    // console.log(confirmar);
    if(confirmar){

      axios.post("/saves/quitar_muchos",dondeObj).then((response) => {
        // console.log('AXIOS: ',response.data,'THIS: ', this, 'CONST: ', updateFavorites );
        updateFavorites(response.data.favorites);

      }).catch((error) => {
          console.log(error);

        }
      );

    }

  }

  handleFavorite(stationId,dataId) {
    // console.log('handleFav: ',stationId, dataId);
    const updateFavorites = this.updateFavorites;
    axios.post("/saves/si_gusta",{ stationId, dataId }).then((response) => {
      // console.log('AXIOS: ',response.data,'THIS: ', this, 'CONST: ', updateFavorites );
      updateFavorites(response.data.favorites);

    }).catch((error) => {
        console.log(error);

      }
    );
  }

  handleUnfavorite(stationId,dataId) {
    // console.log('handleUnfav: ',stationId, dataId);
    const updateFavorites = this.updateFavorites;
    axios.post("/saves/no_gusta",{ stationId, dataId }).then((response) => {
      // console.log('AXIOS: ',response.data,'THIS: ', this, 'CONST: ', updateFavorites );
      updateFavorites(response.data.favorites);

      }).catch((error) => {
        console.log(error);

      });
  }

  handleLogout() {
    axios.get("/logout").then((response) => {
      this.setState(response.data);

    }).catch((error) => {
      console.log(error);

    });
  }

  openTC() {
    const TCPrivacy = true,
    tcp_key = 'terms_and_conditions';
    this.setState({TCPrivacy,tcp_key});
  }

  openPrivacy() {
    const TCPrivacy = true,
    tcp_key = 'privacy_policy';
    this.setState({TCPrivacy,tcp_key});
  }

  openFAQs() {
    const TCPrivacy = true,
    tcp_key = 'help_faqs';
    this.setState({TCPrivacy,tcp_key});
  }

  closeTCPrivacy() {
    window.history.pushState(null,null,'/');
    this.setState({TCPrivacy:false});
  }

  renderTCPrivacy(){
    const state = this.state,
    TCPrivacy = state.TCPrivacy,
    closeTCPrivacy = this.closeTCPrivacy,
    tcp_key = state.tcp_key;
    return <Modal size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={TCPrivacy}
      onHide={closeTCPrivacy}
      id='mobileModal'
    >
      <Modal.Header closeButton>
          <Modal.Title>
            { 
              tcp_key === 'terms_and_conditions'
              && 'Terms & Conditions'
            }
            { 
              tcp_key === 'privacy_policy'
              && 'Privacy Policy'
            }
            { 
              tcp_key === 'help_faqs'
              && 'FAQs'
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            tcp_key === 'terms_and_conditions'
            && <TermsConditions />
          }
          {
            tcp_key === 'privacy_policy'
            && <PrivacyPolicy />
          }
          {
            tcp_key === 'help_faqs'
            && <HelpFAQs />
          }
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeTCPrivacy}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  }

  renderGoogle() {
    if ('valid_user' in this.state && !(this.state.valid_user) ) {
        return <LoginHome />

    } else if ('loading' in this.state && this.state.loading) {
      return <NekoLoader />

    } else if ('stations' in this.state) {
      // console.log("App: ",this.state);
      const resetShuffle = this.resetShuffle,
      handleLogout = this.handleLogout,
      updatePerfil = this.updatePerfil,
      handleFavorite = this.handleFavorite,
      handleUnfavorite = this.handleUnfavorite,
      quitarMuchosFavs = this.quitarMuchosFavs,
      openFAQs = this.openFAQs;
      return <GoogleMap {...this.state}
        resetShuffle={resetShuffle}
        handleLogout={handleLogout}
        updatePerfil={updatePerfil}
        handleFavorite={handleFavorite} 
        handleUnfavorite={handleUnfavorite}
        quitarMuchosFavs={quitarMuchosFavs}
        openFAQs={openFAQs}
      />

    } else {
      return <NekoLoader />

    }
  }
 
  render() {
    const openPrivacy = this.openPrivacy,
    openTC = this.openTC,
    renderTCPrivacy = this.renderTCPrivacy;
    return (
      <div className="App" style={{ height: '100%', width: '100%' }}>
        {this.renderGoogle()}
        <div onClick={openTC} id="termsConditions" className="TCPrivacy">
          <i className="fas fa-file-contract fa-2x"></i>
        </div>
        <div onClick={openPrivacy} id="privacyPolicy" className="TCPrivacy">
          <i className="fas fa-user-secret fa-2x"></i>
        </div>
        {renderTCPrivacy()}
      </div>
    );
  }
}
 
export default App;
