import React, { Component } from 'react';
import curbchildren_cover from '../img/curbchildren_cover.png';
import lostokyo_shirts from '../img/grey_beer.png';
import lostokyo_hats from '../img/green_snapback.png';
import lostokyo_hoodies from '../img/white_hoodie.png';
import sevenproxy_pins from '../img/sevenproxy_pins.png';
class Ads extends Component {

  constructor(props) {
    super(props);
    this.state = {
      curbchildren_cover,
      lostokyo_shirts,
      lostokyo_hats,
      lostokyo_hoodies,
      sevenproxy_pins
    }
  }

  render() {
  	const adsObj = {},
    curbchildren_cover = this.state.curbchildren_cover,
    lostokyo_shirts = this.state.lostokyo_shirts,
    lostokyo_hats = this.state.lostokyo_hats,
    lostokyo_hoodies = this.state.lostokyo_hoodies,
    sevenproxy_pins = this.state.sevenproxy_pins,
  	ads_key = this.props.advertiser;
  	// console.log('Ads! ',this.props.advertiser);
  	switch(ads_key){
  		case 'LEGO':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=115554.10001551&subid=0&type=4"
  			adsObj['alt'] = "LEGO Brand Retail"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=115554.10001551&subid=0&type=4&gridnum=0"
  			break;
  		case 'NEW_AGE':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=764940.16&subid=0&type=4"
  			adsObj['alt'] = "New Age Beverages"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=764940.16&subid=0&type=4&gridnum=5"
  			break;
  		case 'PURE_VPN':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=753668.276&subid=0&type=4"
  			adsObj['alt'] = "Best VPN Service (English Banners)"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=753668.276&subid=0&type=4&gridnum=0"
  			break;
  		case 'NONI':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=764921.3&subid=0&type=4"
  			adsObj['alt'] = "Noni NewAge"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=764921.3&subid=0&type=4&gridnum=0"
  			break;
  		case 'SKY_MOMENT':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=748012.4&subid=0&type=4"
  			adsObj['alt'] = "Custom Star Map"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=748012.4&subid=0&type=4&gridnum=9"
  			break;
  		case 'DOLLAR':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=171423.10000062&subid=0&type=4"
  			adsObj['alt'] = "Dollar Rent-a-Car, Inc."
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=171423.10000062&subid=0&type=4&gridnum=13"
  			break;
  		case 'THRIFTY':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=171424.10000085&subid=0&type=4"
  			adsObj['alt'] = "Thrifty Rent-A-Car System, Inc."
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=171424.10000085&subid=0&type=4&gridnum=13"
  			break;
  		case 'VETERANS':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=647647.10000092&subid=0&type=4"
  			adsObj['alt'] = "Veterans Advantage PBC"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=647647.10000092&subid=0&type=4&gridnum=6"
  			break;
  		case 'EVISU':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=706445.6&subid=0&type=4"
  			adsObj['alt'] = "Evisu HK"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=706445.6&subid=0&type=4&gridnum=13"
  			break;
  		case 'ORLY':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=703006.50&subid=0&type=4"
  			adsObj['alt'] = "ORLY"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=703006.50&subid=0&type=4&gridnum=0"
  			break;
  		case 'TASCHEN':
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=518265.988&subid=0&type=4"
  			adsObj['alt'] = "TASCHEN"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=518265.988&subid=0&type=4&gridnum=13"
  			break;
      case 'GWENISS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=680859.4&subid=0&type=4&LSNSUBSITE=LSNSUBSITE"
        adsObj['alt'] = "Gweniss"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=680859.4&subid=0&type=4&gridnum=4"
        break;
      case 'COLTORTI':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=597710.8&subid=0&type=4"
        adsObj['alt'] = "Coltorti Boutique"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=597710.8&subid=0&type=4&gridnum=7"
        break;
      case 'MARIMEKKO':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=571616.55&subid=0&type=4"
        adsObj['alt'] = "marimekko"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=571616.55&subid=0&type=4&gridnum=9"
        break;
      case 'ORGANIC_BABY':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=694734.12&subid=0&type=4"
        adsObj['alt'] = "Organic Baby Food GmbH"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=694734.12&subid=0&type=4&gridnum=4"
        break;
      case 'BOWLERS_MART':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=756453.7&subid=0&type=4"
        adsObj['alt'] = "BowlersMart.com"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=756453.7&subid=0&type=4&gridnum=14"
        break;
      case 'DIAMOND_CANDLES':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=744108.17&subid=0&type=4"
        adsObj['alt'] = "Diamond Candles"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=744108.17&subid=0&type=4&gridnum=13"
        break;
      case 'PRO_SUPPS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=775817.3&subid=0&type=4&LSNSUBSITE=LSNSUBSITE"
        adsObj['alt'] = "Professional Supplement Center 10% Off and Free Shipping"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=775817.3&subid=0&type=4&gridnum=14"
        break;
      case 'PRIMITIVE_SKATE':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=707006.7&subid=0&type=4"
        adsObj['alt'] = "Primitive Skate"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=707006.7&subid=0&type=4&gridnum=0"
        break;
      case 'SMARTER_LOANS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=720147.5&subid=0&type=4"
        adsObj['alt'] = "Smarter Loans 300x600"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=720147.5&subid=0&type=4&gridnum=19"
        break;
      case 'WANDER_BEAUTY':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=689936.17&subid=0&type=4"
        adsObj['alt'] = "Wander Beauty"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=689936.17&subid=0&type=4&gridnum=13"
        break;
      case 'SIMPLE_CANVAS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=761731.7&subid=0&type=4"
        adsObj['alt'] = "Simple Canvas Prints"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=761731.7&subid=0&type=4&gridnum=13"
        break;
      case 'BAKED_MELISSA':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=760083.9&subid=0&type=4"
        adsObj['alt'] = "Baked by Melissa - Evergreen"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=760083.9&subid=0&type=4&gridnum=0"
        break;
      case 'OUTDOOR_COOKING':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=706754.10&subid=0&type=4"
        adsObj['alt'] = "Outdoor Cooking"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=706754.10&subid=0&type=4&gridnum=13"
        break;
      case 'REAL_KETONES':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=660525.76&subid=0&type=4"
        adsObj['alt'] = "Real Ketones"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=660525.76&subid=0&type=4&gridnum=13"
        break;
      case 'WAFT':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=717601.23&subid=0&type=4"
        adsObj['alt'] = "WAFT Logo"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=717601.23&subid=0&type=4&gridnum=0"
        break;
      case 'WINDWEATHER':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=663068.19&subid=0&type=4"
        adsObj['alt'] = "Wind and Weather"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=663068.19&subid=0&type=4&gridnum=13"
        break;
      case 'MAGIC_CABIN':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=663013.10000838&subid=0&type=4"
        adsObj['alt'] = "Magic Cabin"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=663013.10000838&subid=0&type=4&gridnum=13"
        break;
      case 'LEAM':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=652903.161&type=4&subid=0"
        adsObj['alt'] = "Leam - Luxury Shopping Online"
        adsObj['src'] = "https://www.leam.com/media/rakuten/P19/balenciaga9.jpg"
        adsObj['src2'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=652903.161&type=4&subid=0"
        break;
      case 'HEARTHSONG':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=498087.10000967&subid=0&type=4"
        adsObj['alt'] = "HearthSong"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=498087.10000967&subid=0&type=4&gridnum=13"
        break;
      case 'TRAILER_PARTS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=595115.36&subid=0&type=4"
        adsObj['alt'] = "TheTrailerPartsOutlet.com - shop now!"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=595115.36&subid=0&type=4&gridnum=0"
        break;
      case 'GVM':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=768379.22&subid=0&type=4"
        adsObj['alt'] = "GVM LED"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=768379.22&subid=0&type=4&gridnum=0"
        break;
      case 'FLORSHEIM':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=509443.10000370&subid=0&type=4"
        adsObj['alt'] = "Florsheim"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=509443.10000370&subid=0&type=4&gridnum=15"
        break;
      case 'HANAH':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=756628.3&subid=0&type=4"
        adsObj['alt'] = "HANAH"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=756628.3&subid=0&type=4&gridnum=0"
        break;
      case 'HELM':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=759612.9&subid=0&type=4"
        adsObj['alt'] = "HELM Boots- $50 Off Your First Purchase"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=759612.9&subid=0&type=4&gridnum=13"
        break;
      case 'NUNN':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=509589.10000116&subid=0&type=4"
        adsObj['alt'] = "Nunn Bush"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=509589.10000116&subid=0&type=4&gridnum=11"
        break;
      case 'OYNB':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=748614.14&subid=0&type=4"
        adsObj['alt'] = "OYNB Ltd"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=748614.14&subid=0&type=4&gridnum=0"
        break;
      case 'STACY_ADAMS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=509570.10000267&subid=0&type=4"
        adsObj['alt'] = "Stacy Adams"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=509570.10000267&subid=0&type=4&gridnum=11"
        break;
      case 'STYLEVANA':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=701934.36&type=4&subid=0"
        adsObj['alt'] = "Stylevana"
        adsObj['src'] = "https://www.stylevana.com/images/BU/chuu 300x250.jpg"
        adsObj['src2'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=701934.36&type=4&subid=0"
        break;
      case 'MAGIC_KITCHEN':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=183278.10000380&subid=0&type=4"
        adsObj['alt'] = "MagicKitchen.com"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=183278.10000380&subid=0&type=4&gridnum=4"
        break;
      case 'MASTER_DYNAMIC':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=651019.322&subid=0&type=4"
        adsObj['alt'] = "MH40 Wireless Over-Ear Headphones"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=651019.322&subid=0&type=4&gridnum=12"
        break;
      case 'PLOW_HEARTH':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=486033.10001126&subid=0&type=4"
        adsObj['alt'] = "Plow & Hearth"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=486033.10001126&subid=0&type=4&gridnum=10"
        break;
      case 'FANCY_SPRINKLES':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=761312.8&subid=0&type=4"
        adsObj['alt'] = "Fancy Sprinkles"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=761312.8&subid=0&type=4&gridnum=1"
        break;
      case '1866':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=655452.38&subid=0&type=4"
        adsObj['alt'] = "Magee 1866"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=655452.38&subid=0&type=4&gridnum=9"
        break;
      case 'BUGATCHI':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=581986.40&subid=0&type=4"
        adsObj['alt'] = "Bugatchi"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=581986.40&subid=0&type=4&gridnum=0"
        break;
      case 'NOIZE_US':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=770493.5&subid=0&type=4"
        adsObj['alt'] = "Noize US"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=770493.5&subid=0&type=4&gridnum=13"
        break;
      case '0CM':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=664550.4&subid=0&type=4"
        adsObj['alt'] = "0cm"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=664550.4&subid=0&type=4&gridnum=17"
        break;
      case 'DOCKATOT':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=638014.51&subid=0&type=4"
        adsObj['alt'] = "DockATot"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=638014.51&subid=0&type=4&gridnum=0"
        break;
      case 'GIFTFACE':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=725626.3&subid=0&type=4"
        adsObj['alt'] = "GiftWrapMyFace"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=725626.3&subid=0&type=4&gridnum=13"
        break;
      case 'LIFE_HOME':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=645454.10&subid=0&type=4"
        adsObj['alt'] = "Life and Home"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=645454.10&subid=0&type=4&gridnum=13"
        break;
      case 'STUDENT_BEANS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=681542.10&subid=0&type=4"
        adsObj['alt'] = "Des réductions étudiantes simplifiées"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=681542.10&subid=0&type=4&gridnum=0"
        break;
      case 'JETSON':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=758410.13&subid=0&type=4"
        adsObj['alt'] = "IMMUNITY"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=758410.13&subid=0&type=4&gridnum=0"
        break;
      case 'VELOSOCK':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=679150.3&subid=0&type=4"
        adsObj['alt'] = "Velosock"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=679150.3&subid=0&type=4&gridnum=0"
        break;
      case 'MAISON_FASHION':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=698955.3&subid=0&type=4"
        adsObj['alt'] = "Maison De Fashion"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=698955.3&subid=0&type=4&gridnum=0"
        break;
      case 'DESIRE_MAYA':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=754269.5&subid=0&type=4"
        adsObj['alt'] = "Original Group-DRMPR"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=754269.5&subid=0&type=4&gridnum=13"
        break;
      case 'BN3TH':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=718559.4&subid=0&type=4"
        adsObj['alt'] = "BN3TH"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=718559.4&subid=0&type=4&gridnum=11"
        break;
      case 'FRANK_PLAN':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=459242.106&subid=0&type=4"
        adsObj['alt'] = "FranklinPlanner"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=459242.106&subid=0&type=4&gridnum=5"
        break;
      case 'ANKER':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=724082.21&subid=0&type=4"
        adsObj['alt'] = "Anker Technologies"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=724082.21&subid=0&type=4&gridnum=13"
        break;
      case 'CHAADS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=801531.4&subid=0&type=4"
        adsObj['alt'] = "Chaads"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=801531.4&subid=0&type=4&gridnum=0"
        break;
      case 'DRESSBARN':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=762944.23&subid=0&type=4"
        adsObj['alt'] = "Dressbarn"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=762944.23&subid=0&type=4&gridnum=0"
        break;
      case 'PONDEN':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=449156.127&subid=0&type=4"
        adsObj['alt'] = "Ponden Home Interiors"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=449156.127&subid=0&type=4&gridnum=17"
        break;
      case 'DIS':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=794981.3&subid=0&type=4"
        adsObj['alt'] = "Discount Italian Shoes"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=794981.3&subid=0&type=4&gridnum=0"
        break;
      case 'GATE_DIRECT':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=788884.6&type=4&subid=0"
        adsObj['alt'] = "Gate Operators Direct"
        adsObj['src'] = "https://cdn.shopify.com/s/files/1/0290/3310/3394/files/Gate_Operators.jpg?v=1591730032"
        adsObj['src2'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=788884.6&type=4&subid=0"
        break;
      case 'IVACY':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=769922.134&type=4&subid=0"
        adsObj['alt'] = "Ivacy VPN"
        adsObj['src'] = "https://www.ivacy.com/wp-content/uploads/2020/05/468-x-60.png"
        adsObj['src2'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=769922.134&type=4&subid=0"
        break;
      case 'NORD':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=572706.45&subid=0&type=4"
        adsObj['alt'] = "nordvpn"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=572706.45&subid=0&type=4&gridnum=14"
        break;
      case 'QATAR':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=551783.233&subid=0&type=4"
        adsObj['alt'] = "Qatar Airways"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=551783.233&subid=0&type=4&gridnum=9"
        break;
      case 'SMARTY_PARTY':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=692485.8&subid=0&type=4"
        adsObj['alt'] = "Smarty Had A Party"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=692485.8&subid=0&type=4&gridnum=0"
        break;
      case 'CHIROMATIC':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=798119.8&subid=0&type=4"
        adsObj['alt'] = "Chiromatic Sleep Systems"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=798119.8&subid=0&type=4&gridnum=4"
        break;
      case 'PUTTERBALL':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=709387.12&subid=0&type=4"
        adsObj['alt'] = "Putterball Game"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=709387.12&subid=0&type=4&gridnum=1"
        break;
      case 'TRESTIQUE':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=778363.6&subid=0&type=4"
        adsObj['alt'] = "Selfie Shade Match - trèStiQue"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=778363.6&subid=0&type=4&gridnum=19"
        break;
      case 'ASTTERIA':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=720038.33&type=4&subid=0"
        adsObj['alt'] = "Forever-970x250"
        adsObj['src'] = "https://www.astteria.com/media/rakuten/moments-2020/Forever-970x250.jpg"
        adsObj['src2'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=720038.33&type=4&subid=0"
        break;
      case 'TEMPTATION_CRUISE':
        adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=754244.14&subid=0&type=4"
        adsObj['alt'] = "Original Group-Temptation"
        adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=754244.14&subid=0&type=4&gridnum=13"
        break;
      case 'CURBCHILDREN_NOVEL':
        adsObj['href'] = "https://curbchildren.com/buy"
        adsObj['alt'] = "Curb Children Novel"
        adsObj['src'] = curbchildren_cover
        break;
      case 'LOSTOKYO_SHIRTS':
        adsObj['href'] = "https://lostokyo.store/collections/t-shirts"
        adsObj['alt'] = "Los Tokyo Shirts"
        adsObj['src'] = lostokyo_shirts
        break;
      case 'LOSTOKYO_HATS':
        adsObj['href'] = "https://lostokyo.store/collections/hats"
        adsObj['alt'] = "Los Tokyo Hats"
        adsObj['src'] = lostokyo_hats
        break;
      case 'LOSTOKYO_HOODIES':
        adsObj['href'] = "https://lostokyo.store/collections/hoodies"
        adsObj['alt'] = "Los Tokyo Hoodies"
        adsObj['src'] = lostokyo_hoodies
        break;
      case 'SEVENPROXY_PINS':
        adsObj['href'] = "https://sevenproxypress.com/pins"
        adsObj['alt'] = "Seven Proxy Pins"
        adsObj['src'] = sevenproxy_pins
        break;
  		default:
  			adsObj['href'] = "https://click.linksynergy.com/fs-bin/click?id=*olCW7BWDdU&offerid=115554.10001551&subid=0&type=4"
  			adsObj['alt'] = "LEGO Brand Retail"
  			adsObj['src'] = "https://ad.linksynergy.com/fs-bin/show?id=*olCW7BWDdU&bids=115554.10001551&subid=0&type=4&gridnum=0"
  	}
    return (<div className="boleto opaque-white">

		<a href={adsObj.href} target="_blank" rel="noopener">
			<div className='bizURL adsURL'>
				{'Our amigos have goods if you have coin...'}
			</div>
		</a>
		<hr/>
		<a href={adsObj.href} target="_blank" rel="noopener">
			<img border="0" className='adsImg'
			alt={adsObj.alt}
			src={adsObj.src} />
		</a>
    { 'src2' in adsObj &&
      <img border="0" width="1" height="1"
      alt="alt2"
      src={adsObj.src2} />
    }
	</div>);
  }
}

export default Ads;